import {
    SET_HEAP_EXTERNAL_ID,
    SET_EMAIL,
    SET_USERNAME,
    UserActionTypes,
    UserState
} from './types';

export const initialState: UserState = {
    email: '',
    username: '',
    heap_external_id: ''
};

export const userReducer = (
    state = initialState,
    action: UserActionTypes
): UserState => {
    switch (action.type) {
        case SET_EMAIL:
            return { ...state, email: action.payload };
        case SET_USERNAME:
            return { ...state, username: action.payload };
        case SET_HEAP_EXTERNAL_ID:
            return { ...state, heap_external_id: action.payload };
        default:
            return state;
    }
};
