import { AuthOptions } from '@aws-amplify/auth/lib-esm/types/Auth';
import { PersonProfile } from '../utils/person-profile-parser';

export enum Stage {
    LOGIN = 'LOGIN',
    CODE = 'CODE'
}

export interface IEnvConfig {
    region: string;
    apiRegion: string;
    identityPoolId: string;
    apiGatewayEndpoint: string;
    apiGatewayStage: string;
    allowCustomTargetHost: boolean;
    clientErrorURL: string;
    destinations: IDestinations;
    localConfig?: IRemoteConfig;
}

export interface IDestinations {
    [property: string]: IDestination;
}

export interface IDestination {
    loginURL: string;
    logoutURL: string;
    loginType: string;
    activationEnabled: string;
    idToken: string;
    personProfile: PersonProfile;
}

export interface IRemoteConfig {
    auth: AuthOptions;
}

export interface IRedirectConfig {
    destination: string;
    TARGET?: string;
    targetHost?: string;
}
