import React from 'react';
import { useIntl } from 'react-intl';
// @ts-ignore
import { InfoPanel } from '@workhuman/react-aurora-infopanel';
import { withLayout } from '../shared/withLayout/withLayout';
import { ReactComponent as WarningIcon } from './warning-line.svg';
import styles from './Error.module.scss';

export const Error = withLayout(() => {
    const intl = useIntl();
    const heading = intl.formatMessage({
        id: 'error__heading',
        defaultMessage: 'Oops! Something went wrong!'
    });
    const description = intl.formatMessage({
        id: 'error__description',
        defaultMessage: 'Something went wrong, please try again later.'
    });
    return (
        <div className={styles['error']} data-testid="errorPageContainer">
            <WarningIcon className="a-icon a-icon--iconColor a-icon--xxl" />

            <InfoPanel heading={heading} description={description} />
        </div>
    );
}, false);
