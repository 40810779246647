import { ComponentType } from 'react';
import { MessageDescriptor } from 'react-intl';

import { Signup } from '../components/Signup/Signup';
import { EmailConfirmation } from '../components/EmailConfirmation/EmailConfirmation';
import { Login } from '../components/Login/Login';
import { ResendConfirmation } from '../components/ResendConfirmation/ResendConfirmation';
import { EmailConfirmationSent } from '../components/EmailConfirmationSent/EmailConfirmationSent';
import { ForgotPassword } from '../components/ForgotPassword/ForgotPassword';
import { ActivateAccount } from '../components/ActivateAccount/ActivateAccount';
import { PasswordResetRequired } from '../components/PasswordResetRequired/PasswordResetRequired';
import { PasswordInstructionsSent } from '../components/PasswordInstructionsSent/PasswordInstructionsSent';
import { UpdatePassword } from '../components/UpdatePassword/UpdatePassword';
import { Error } from '../components/Error/Error';
import { InvalidCodeNotification } from '../components/InvalidCodeNotification/InvalidCodeNotification';
import { SSOInitializer } from '../SSOInitializer/SSOInitializer';
import { Logout } from '../components/Logout/Logout';
import { LogoutRedirect } from '../components/LogoutRedirect/LogoutRedirect';
import messages from './messages';

export interface Route {
    path: string;
    component: ComponentType;
    isPrivate: boolean;
    title?: MessageDescriptor;
}

export const SIGN_UP: Route = {
    path: '/',
    component: Signup,
    isPrivate: false,
    title: messages.signUp
};

export const EMAIL_CONFIRMATION: Route = {
    path: '/auth/confirm',
    component: EmailConfirmation,
    isPrivate: false,
    title: messages.emailConfirmation
};

export const RESEND_CONFIRMATION: Route = {
    path: '/resendconfirmation',
    component: ResendConfirmation,
    isPrivate: false,
    title: messages.resendConfirmation
};

export const CONFIRMATION_SENT: Route = {
    path: '/confirmationsent',
    component: EmailConfirmationSent,
    isPrivate: false,
    title: messages.confirmationSent
};

export const ERROR: Route = {
    path: '/error',
    component: Error,
    isPrivate: false,
    title: messages.error
};

export const INVALID_NOTIFICATION: Route = {
    path: '/invalidnotification',
    component: InvalidCodeNotification,
    isPrivate: false,
    title: messages.invalidNotification
};

export const LOGIN: Route = {
    path: '/login',
    component: Login,
    isPrivate: false,
    title: messages.login
};

export const LOGOUT: Route = {
    path: '/logout',
    component: Logout,
    isPrivate: false,
    title: messages.logout
};

export const LOGOUT_REDIRECT: Route = {
    path: '/logout-redirect',
    component: LogoutRedirect,
    isPrivate: false,
    title: messages.logout
};

export const UPDATE_PASSWORD: Route = {
    path: '/update-password',
    component: UpdatePassword,
    isPrivate: false,
    title: messages.updatePassword
};

export const INSTRUCTIONS_SENT: Route = {
    path: '/instructionssent',
    component: PasswordInstructionsSent,
    isPrivate: false,
    title: messages.instructionsSent
};

export const RESET_REQUIRED: Route = {
    path: '/resetrequired',
    component: PasswordResetRequired,
    isPrivate: false,
    title: messages.resetRequired
};

export const ACTIVATE_ACCOUNT: Route = {
    path: '/activateaccount',
    component: ActivateAccount,
    isPrivate: true,
    title: messages.activateAccount
};

export const FORGOT_PASSWORD: Route = {
    path: '/forgotpassword',
    component: ForgotPassword,
    isPrivate: false,
    title: messages.forgotPassword
};

export const SSO_INIT_WITH_PATH_PARAMS: Route = {
    path: '/sso-init/:clientPathParam/:idpTypePathParam',
    component: SSOInitializer,
    isPrivate: false,
    title: messages.ssoInitializer
};

export const SSO_INIT: Route = {
    path: '/sso-init',
    component: SSOInitializer,
    isPrivate: false,
    title: messages.ssoInitializer
};

export const APP_ROUTES: Route[] = [
    UPDATE_PASSWORD,
    RESET_REQUIRED,
    RESEND_CONFIRMATION,
    LOGIN,
    LOGOUT,
    INVALID_NOTIFICATION,
    INSTRUCTIONS_SENT,
    FORGOT_PASSWORD,
    ERROR,
    EMAIL_CONFIRMATION,
    CONFIRMATION_SENT,
    ACTIVATE_ACCOUNT,
    SIGN_UP
];

export const findRouteByPath = (searchPath: string) =>
    APP_ROUTES.find(({ path }) => searchPath === path);
