// @ts-ignore
import { initAnonymously, resetIdentity } from 'heap-analytics';

/**
 * Initialise the underlying web analytics component.
 *
 */

export const initHeap = () => {
    initAnonymously(true)
        .then(() => {
            resetIdentity();
        })
        .catch((error: any) => {
            console.error(
                error.message,
                'Unable to initialize heap analytics. Failed to get properties.'
            );
        });
};
