import React, {
    FunctionComponent,
    createContext,
    useReducer,
    useEffect,
    useContext,
    useState
} from 'react';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/es';
// @ts-ignore
import { Loader } from '@workhuman/react-aurora-loader';
import { LangLocaleState, LangLocaleActionTypes } from './types';
import { initialState, langLocaleReducer } from './reducer';
import { LANGUAGE_STORAGE_KEY } from '../../config/language/language-config';
import { useLocalStorage } from '../../hooks/useLocalStorage/useLocalStorage';

type LangLocaleContextState = [
    LangLocaleState,
    React.Dispatch<LangLocaleActionTypes>
];

export const LangLocaleContext = createContext<LangLocaleContextState>([
    initialState,
    () => null
]);

interface IntlMessages {
    [key: string]: string;
}

interface LanguageMessages {
    language: string;
    messages: IntlMessages;
}

const getLoadedMessagesForLanguage = (
    messages: LanguageMessages[],
    language: string
) => {
    return messages.find(m => m.language === language);
};

export const LangLocaleContextProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(langLocaleReducer, initialState);
    const [, setStoredLanguage] = useLocalStorage(LANGUAGE_STORAGE_KEY);
    const [loadedMessages, setLoadedMessages] = useState<LanguageMessages[]>(
        []
    );
    const [currentMessages, setCurrentMessages] = useState<IntlMessages>();
    const [hasDoneInitialLoad, setHasDoneInitialLoad] = useState(false);

    useEffect(() => {
        const loadMessages = async () => {
            const { language } = state;
            const alreadyLoadedMessages = getLoadedMessagesForLanguage(
                loadedMessages,
                language
            );
            if (alreadyLoadedMessages) {
                setCurrentMessages(alreadyLoadedMessages.messages);
            } else {
                const loaded = await import(
                    `../../../translations/${language}.json`
                );
                const messages = loaded.default as IntlMessages;
                setLoadedMessages([...loadedMessages, { language, messages }]);
                setCurrentMessages(messages);
            }

            setHasDoneInitialLoad(true);
        };

        loadMessages();
    }, [state, loadedMessages]);

    useEffect(() => setStoredLanguage(state.language), [
        state,
        setStoredLanguage
    ]);

    return (
        <LangLocaleContext.Provider value={[state, dispatch]}>
            <IntlProvider
                locale={state.language}
                key={state.language}
                messages={currentMessages}
            >
                {hasDoneInitialLoad ? (
                    children
                ) : (
                    <div
                        style={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Loader />
                    </div>
                )}
            </IntlProvider>
        </LangLocaleContext.Provider>
    );
};

export const useLangLocale = () => useContext(LangLocaleContext);
