import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// @ts-ignore
import { Select } from '@workhuman/react-aurora-select';
import { ReactComponent as ChevronUpIcon } from './chevron-up.svg';
import { useLangLocale, setLanguage } from '../../../store';
import {
    AVAILABLE_LANGUAGES,
    DEFAULT_LANGUAGE
} from '../../../config/language/language-config';
import styles from './LanguagePicker.module.scss';

export const LanguagePicker = () => {
    const [{ language }, dispatch] = useLangLocale();
    const intl = useIntl();

    const languagesByCode = useMemo(
        () =>
            AVAILABLE_LANGUAGES.map(({ code, translation }) => ({
                code,
                value: intl.formatMessage(translation)
            })),
        [intl]
    );

    const pickerLanguages = useMemo(
        () => languagesByCode.map(({ value }) => value),
        [languagesByCode]
    );

    const selectedLanguage = useMemo(() => {
        const current = languagesByCode.find(({ code }) => code === language);
        return current ? current.value : 'Default';
    }, [language, languagesByCode]);

    const onPickerChange = useCallback(
        (newLanguage: string) => {
            const mappedLang = languagesByCode.find(
                ({ value }) => value === newLanguage
            );
            const newLanguageCode = mappedLang
                ? mappedLang.code
                : DEFAULT_LANGUAGE;
            dispatch(setLanguage(newLanguageCode));
        },
        [languagesByCode, dispatch]
    );

    return (
        <Select
            id="languagePicker"
            label="Select language"
            className={styles['languageSelector']}
            labelledby="languagePicker"
            data-testid="languagePicker"
            selectData={pickerLanguages}
            selectValue={selectedLanguage}
            onChange={onPickerChange}
            enableShowMenuAbove={false}
        >
            <ChevronUpIcon
                className={clsx(
                    styles['languagePicker-arrow'],
                    'a-icon a-icon--xs a-icon--iconColor'
                )}
            />
        </Select>
    );
};
