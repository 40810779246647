import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Spinner } from '../shared/Spinner/Spinner';
import { withLayout } from '../shared/withLayout/withLayout';
import { UserAuthForm } from '../shared/UserAuthForm/UserAuthForm';
import { useCurrentUser, setEmail as setAppEmail } from '../../store';
import { INVALID_PARAMS } from '../../utils/cognito-error-codes';
import { FormFieldMessages } from '../shared/UserAuthForm/types';

export const ForgotPassword = withLayout(() => {
    const intl = useIntl();
    const history = useHistory();
    const [{ email: defaultEmail }, dispatch] = useCurrentUser();

    const [email, setEmail] = useState(defaultEmail);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onEmailChange = useCallback(
        (newEmail: string) => {
            dispatch(setAppEmail(newEmail));
            setEmail(newEmail);
        },
        [setEmail, dispatch]
    );

    const onValidationChange = useCallback((allFieldsValid: boolean) => {
        setIsFormValid(allFieldsValid);
    }, []);

    const doForgotPassword = useCallback(() => {
        setIsLoading(true);
        Auth.forgotPassword(email)
            .then(() => {
                history.push('/instructionssent');
            })
            .catch(err => {
                switch (err.code) {
                    case INVALID_PARAMS:
                        history.push('/resendconfirmation');
                        break;
                    default:
                        history.push('/error');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [email, history]);

    const formTitle = intl.formatMessage({
        id: 'forgotPassword__title',
        defaultMessage: 'Forgot password'
    });
    const formDescription = intl.formatMessage({
        id: 'forgotPassword__description',
        defaultMessage: 'It happens to us all.'
    });
    const submitBtnLabel = intl.formatMessage({
        id: 'forgotPassword__submit',
        defaultMessage: 'Send update instructions'
    });

    const emailFieldMessages: FormFieldMessages = {
        error: {
            invalid: intl.formatMessage({
                id: 'forgotPassword__emailInvalid',
                defaultMessage: 'Please enter a valid email address'
            }),
            empty: intl.formatMessage({
                id: 'forgotPassword__emptyEmail',
                defaultMessage: 'Please provide email'
            })
        }
    };

    return (
        <UserAuthForm
            id="forgotPasswordForm"
            formTitle={formTitle}
            formDescription={formDescription}
            email={email}
            onEmailChange={onEmailChange}
            hidePassword
            emailFieldMessages={emailFieldMessages}
            passwordFieldMessages={{}}
            submitBtnLabel={isLoading ? <Spinner /> : submitBtnLabel}
            onValidationChange={onValidationChange}
            submitBtnDisabled={!isFormValid || isLoading}
            onSubmit={doForgotPassword}
        />
    );
});
