import {
    CLIENT_METADATA_ADDITIONAL_PARAMS,
    DEFAULT_DESTINATION,
    EMAIL_PATTERN,
    IDENTITY_CONFIG_URL,
    IDENTITY_OAUTH_URL,
    PASSWORD_PATTERN,
    CLIENT_ERROR_BACKEND_URL
} from './constants';
import { Amplify, API, Auth, Hub, Logger } from 'aws-amplify';
import { urlSafeDecode } from '@aws-amplify/core';
import {
    AuthOptions,
    AwsCognitoOAuthOpts
} from '@aws-amplify/auth/lib-esm/types/Auth';
import { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import { OutgoingHttpHeaders } from 'http';
import { IEnvConfig, IRemoteConfig, Stage } from '../SSOInitializer/SSOModels';

const SESSION_STORAGE_KEY_TARGET_HOST = 'identity_target_host';
const SESSION_STORAGE_KEY_TARGET = 'identity_target';
const SESSION_STORAGE_KEY_CLIENT = 'identity_client_name';
const SESSION_STORAGE_KEY_DESTINATION = 'identity_destination';
const SESSION_STORAGE_KEY_TIMEOUT_RETRY_COUNT = 'identity_timeout_retry_count';
const SESSION_STORAGE_KEY_LOGOUT_URL = 'identity_logout_url';
const SESSION_STORAGE_KEY_CODEBUILD_ID = 'identity_codebuild_id';

export const isEmailValid = (email: string) => {
    return EMAIL_PATTERN.test(email);
};

export const isPasswordValid = (password: string) => {
    return PASSWORD_PATTERN.test(password);
};

export const getClientMetadata = (params?: object) => {
    return {
        ...params,
        [CLIENT_METADATA_ADDITIONAL_PARAMS.key_target]:
            CLIENT_METADATA_ADDITIONAL_PARAMS.value_target
    };
};

export const setPageTitle = (pageTitle: string) => {
    if (pageTitle) {
        document.title = pageTitle;
    }
};

export const loadEnvConfig = async (): Promise<IEnvConfig> => {
    console.log('loading environment config');
    const envConfigFetch = await fetch(IDENTITY_CONFIG_URL);
    return await envConfigFetch.json();
};

export const loadRemoteConfig = async (
    envConfig: IEnvConfig,
    idpName: string,
    state: string | null
): Promise<IRemoteConfig> => {
    let remoteConfig;
    if (state) {
        console.log('loading config from state for idpName:', idpName);
        const authConfig = state.split('-')[1];
        if (authConfig) {
            remoteConfig = JSON.parse(urlSafeDecode(authConfig));
        } else {
            throw new Error('Error loading config from state ' + state);
        }
    } else if (envConfig.localConfig) {
        console.log('loading config for localhost for idpName:', idpName);
        return Promise.resolve(envConfig.localConfig);
    } else {
        const apiGwEndpointName = 'AuthConfigAPI';

        Amplify.configure({
            API: {
                endpoints: [
                    {
                        name: apiGwEndpointName,
                        region: envConfig.apiRegion,
                        endpoint: envConfig.apiGatewayEndpoint
                    }
                ]
            }
        });

        console.log('loading config from api for idpName:', idpName);

        remoteConfig = await API.get(
            apiGwEndpointName,
            IDENTITY_OAUTH_URL + idpName,
            { response: false }
        ).catch(error => {
            console.log(error);
            throw new Error(
                'Unable to retrieve identity configuration for ' +
                    idpName +
                    ' ' +
                    error
            );
        });
    }
    return remoteConfig;
};

export const configureAmplify = async (
    remoteConfig: IRemoteConfig
): Promise<AuthOptions> => {
    const amplifyConfig = remoteConfig.auth;
    console.log(
        'configuring amplify for app client:',
        amplifyConfig.userPoolWebClientId
    );

    amplifyConfig.storage = sessionStorage;

    const oauth = amplifyConfig.oauth as AwsCognitoOAuthOpts;
    if (oauth?.domain) {
        // Add protocol and host to the redirect sign-in and sign-out URLs if they don't start with 'http(s)':
        const protocolAndHost =
            window.location.protocol + '//' + window.location.host;
        if (!oauth.redirectSignIn.match('^http')) {
            oauth.redirectSignIn = protocolAndHost + oauth.redirectSignIn;
        }
        if (!oauth.redirectSignOut.match('^http')) {
            oauth.redirectSignOut = protocolAndHost + oauth.redirectSignOut;
        }
    } else {
        // If there is no domain specified in oauth configuration then it is considered as non federated flow.
        // Simply remove oauth object from configuration object to prevent Amplify configuration failures
        console.info(
            'Remove oauth configuration from amplify config for app client:',
            amplifyConfig.userPoolWebClientId
        );
        delete amplifyConfig['oauth'];
    }

    Auth.configure(amplifyConfig);

    return amplifyConfig;
};

export const logAmplifyLifecycleEvents = (): void => {
    const isDebugEnabled =
        localStorage.getItem('IDENTITY_SSO_DEBUG') === 'true';
    if (isDebugEnabled) {
        console.log('DEBUG mode enabled');
        Logger.LOG_LEVEL = 'DEBUG';
        Hub.listen('auth', data => {
            console.log('Auth Event: ' + data.payload.event);
        });
    }
};

export const cleanupSessionStorage = (): void => {
    console.log('cleaning up session storage');
    sessionStorage.removeItem(SESSION_STORAGE_KEY_TARGET_HOST);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_TARGET);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_CLIENT);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_TIMEOUT_RETRY_COUNT);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_LOGOUT_URL);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_CODEBUILD_ID);
};

export const getSSOTimeoutRetryAttempt = (): string | null => {
    return sessionStorage.getItem(
        SESSION_STORAGE_KEY_TIMEOUT_RETRY_COUNT
    ) as string;
};

export const setSSOTimeoutRetryAttempt = (retryAttempt: number): void => {
    sessionStorage.setItem(
        SESSION_STORAGE_KEY_TIMEOUT_RETRY_COUNT,
        String(retryAttempt)
    );
};

export const calcClient = (client?: string): string => {
    let result;
    if (client) {
        result = client;
        sessionStorage.setItem(SESSION_STORAGE_KEY_CLIENT, client);
        console.log('getting client from HTTP params:', result);
    } else {
        result = sessionStorage.getItem(SESSION_STORAGE_KEY_CLIENT) as string;
        console.log('getting client from sessionStorage:', result);
    }
    return result;
};

export const calcTargetHost = (
    allowCustomTargetHost: boolean,
    targetHost?: string
): string | undefined => {
    let result;
    if (allowCustomTargetHost) {
        if (targetHost) {
            result = targetHost;
            sessionStorage.setItem(SESSION_STORAGE_KEY_TARGET_HOST, targetHost);
            console.log('getting targetHost from HTTP params:', result);
        } else {
            result = sessionStorage.getItem(
                SESSION_STORAGE_KEY_TARGET_HOST
            ) as string;
            console.log('getting targetHost from sessionStorage:', result);
        }
    } else if (targetHost) {
        console.log('targetHost is ignored:', targetHost);
    }
    return result;
};

export const calcTarget = (target: string): string => {
    let result;
    if (target) {
        console.log('getting target from HTTP param: ', target);
        result = target;
        sessionStorage.setItem(SESSION_STORAGE_KEY_TARGET, target);
    } else {
        result = sessionStorage.getItem(SESSION_STORAGE_KEY_TARGET) as string;
        if (result) {
            console.log('getting target from sessionStorage: ', result);
        } else {
            console.log(`no target is provided.`);
        }
    }
    return result;
};

export const calcIdpName = (
    idpName: string,
    idpType: string,
    client: string
): string => {
    let result;
    if (idpName) {
        result = idpName;
        console.log('getting idpName from HTTP params:', result);
    } else {
        result = idpType + '-' + client + '-idp';
        console.log(
            'calculating idpName from HTTP params idpType and client:',
            result
        );
    }
    return result;
};

export const calcDestination = (destination?: string): string => {
    let result;
    if (destination) {
        result = destination;
        sessionStorage.setItem(SESSION_STORAGE_KEY_DESTINATION, destination);
        console.log('getting destination from HTTP params:', result);
    } else {
        result = sessionStorage.getItem(
            SESSION_STORAGE_KEY_DESTINATION
        ) as string;
        console.log('getting destination from sessionStorage:', result);
    }
    return result ? result : DEFAULT_DESTINATION;
};

export const hasCodeBuildIdMismatch = (stage: Stage): boolean => {
    let buildId;
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === 'codebuild-number') {
            buildId = metas[i].getAttribute('content');
            console.log('codebuild id: ' + buildId);
            break;
        }
    }

    if (stage === Stage.CODE) {
        let sessionBuildId = sessionStorage.getItem(
            SESSION_STORAGE_KEY_CODEBUILD_ID
        ) as string;
        if (sessionBuildId === buildId) {
            console.log('codebuild id match');
        } else {
            console.log('codebuild id mismatch: ', sessionBuildId);
            return true;
        }
    } else {
        if (buildId) {
            sessionStorage.setItem(SESSION_STORAGE_KEY_CODEBUILD_ID, buildId);
        }
    }
    return false;
};

export const getLogoutURL = (): string => {
    return sessionStorage.getItem(SESSION_STORAGE_KEY_LOGOUT_URL) as string;
};

export const setLogoutURL = (logoutURL: string): void => {
    sessionStorage.setItem(SESSION_STORAGE_KEY_LOGOUT_URL, logoutURL);
};

export const sendErrorToBackend = async (
    errorMessage: string,
    client: string,
    code: string
): Promise<string> => {
    let uuid = '';
    try {
        console.log(`Sending error to server`);

        let currentUsername: string;
        try {
            const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
            currentUsername = currentUser.getUsername();
        } catch (error) {
            currentUsername = '*unauthenticated*';
        }

        let errorPayload = JSON.stringify({
            message: errorMessage,
            url: window.location.href,
            client: client,
            sessionBuildId: sessionStorage.getItem(
                SESSION_STORAGE_KEY_CODEBUILD_ID
            ),
            sessionRetryCount: sessionStorage.getItem(
                SESSION_STORAGE_KEY_TIMEOUT_RETRY_COUNT
            ),
            sessionTarget: sessionStorage.getItem(SESSION_STORAGE_KEY_TARGET),
            sessionTargetHost: sessionStorage.getItem(
                SESSION_STORAGE_KEY_TARGET_HOST
            ),
            user: currentUsername,
            stage: code ? 'CODE' : 'LOGIN'
        });

        const headers: OutgoingHttpHeaders = {
            'Content-Type': 'text/plain'
        };

        await axios
            .post(
                `${CLIENT_ERROR_BACKEND_URL}?client=${client}`,
                errorPayload,
                { headers }
            )
            .then(
                response => {
                    uuid = `UUID: ${response.data}`;
                },
                error => {
                    console.error('Failed sending logs to backend', error);
                }
            );
    } catch (error) {
        console.error(error);
    }
    return uuid;
};
