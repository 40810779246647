import React, {
    createContext,
    useContext,
    useReducer,
    FunctionComponent
} from 'react';
import { UserState, UserActionTypes } from './types';
import { initialState, userReducer } from './reducer';

type UserContextState = [UserState, React.Dispatch<UserActionTypes>];

export const UserContext = createContext<UserContextState>([
    initialState,
    () => null
]);

export const UserContextProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    return (
        <UserContext.Provider value={[state, dispatch]}>
            {children}
        </UserContext.Provider>
    );
};

export const useCurrentUser = () => useContext(UserContext);
