import {
    UserActionTypes,
    SET_EMAIL,
    SET_USERNAME,
    SET_HEAP_EXTERNAL_ID
} from './types';

export const setEmail = (email: string): UserActionTypes => ({
    type: SET_EMAIL,
    payload: email
});

export const setUsername = (username: string): UserActionTypes => ({
    type: SET_USERNAME,
    payload: username
});

export const setHeapExternalId = (
    heap_external_id: string
): UserActionTypes => ({
    type: SET_HEAP_EXTERNAL_ID,
    payload: heap_external_id
});
