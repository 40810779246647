import React, { useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// @ts-ignore
import { addCustomProperties } from 'heap-analytics';
// @ts-ignore
import { Alert } from '@workhuman/react-aurora-alert';
import { Spinner } from '../shared/Spinner/Spinner';
import { useCurrentUser } from '../../store';
import { withLayout } from '../shared/withLayout/withLayout';
import { UserAuthForm } from '../shared/UserAuthForm/UserAuthForm';
import { FormFieldMessages } from '../shared/UserAuthForm/types';
import { AccountMessageLink } from '../shared/AccountMessageLink/AccountMessageLink';
import { getRetireePersonProfile } from '../../utils/person-profile-parser';
import { setEmail as setAppEmail } from '../../store';
import {
    USER_NOT_CONFIRMED,
    PASSWORD_RESET_REQUIRED
} from '../../utils/cognito-error-codes';
import { useForgotPassword } from '../../hooks/useForgotPassword/useForgotPassword';
import { useStoreRedirect } from '../../hooks/useStoreRedirect/useStoreRedirect';
import { ReactComponent as DangerIcon } from '@aurora/icon/svg/danger-filled.svg';
import styles from './Login.module.scss';

export const Login = withLayout(
    () => {
        const intl = useIntl();
        const history = useHistory();
        const [
            { email: defaultEmail, heap_external_id: heapExternalId },
            dispatch
        ] = useCurrentUser();
        const handleForgotPassword = useForgotPassword('/resetrequired');
        const { goToStore, storeRedirectForm } = useStoreRedirect();

        const [email, setEmail] = useState(defaultEmail);
        const [password, setPassword] = useState('');
        const [isFormValid, setIsFormValid] = useState(true);
        const [isLoading, setIsLoading] = useState(false);
        const [showGeneralError, setShowGeneralError] = useState(false);

        const onEmailChange = useCallback(
            (newEmail: string) => {
                dispatch(setAppEmail(newEmail));
                setEmail(newEmail);
            },
            [setEmail, dispatch]
        );

        const onPasswordChange = useCallback(
            (newPassword: string) => setPassword(newPassword),
            [setPassword]
        );

        const onValidationChange = useCallback((allFieldsValid: boolean) => {
            setIsFormValid(allFieldsValid);
        }, []);

        const handleLoginSuccess = useCallback(
            (user: CognitoUser) => {
                const session = user.getSignInUserSession();
                if (session) {
                    const idToken = session.getIdToken();
                    const rawPersonProfiles = idToken.payload.person_profiles;
                    const retireeProfile = getRetireePersonProfile(
                        rawPersonProfiles
                    );

                    if (retireeProfile) {
                        const retireeClient = retireeProfile.client;
                        const heapExternalGuid =
                            heapExternalId ||
                            idToken.payload['heap_external_id'];
                        const customDimensions = {
                            identityExternalId: heapExternalGuid
                        };

                        /*
                         * Set custom user props to Heap
                         * */
                        addCustomProperties(retireeClient, customDimensions);
                        goToStore(idToken.getJwtToken(), retireeProfile);
                    } else {
                        history.push('/activateaccount');
                    }
                }
            },
            [history, goToStore, heapExternalId]
        );

        const handleLoginFailure = useCallback(
            error => {
                setPassword('');
                switch (error.code) {
                    case USER_NOT_CONFIRMED:
                        history.push('/resendconfirmation');
                        break;
                    case PASSWORD_RESET_REQUIRED:
                        handleForgotPassword(email);
                        break;
                    default:
                        setShowGeneralError(true);
                }
            },
            [history, email, handleForgotPassword]
        );

        const doLogin = useCallback(() => {
            setIsLoading(true);
            setShowGeneralError(false);
            Auth.signIn({ username: email, password })
                .then(result => {
                    setIsLoading(false);
                    handleLoginSuccess(result);
                })
                .catch(err => {
                    setIsLoading(false);
                    handleLoginFailure(err);
                });
        }, [email, password, handleLoginSuccess, handleLoginFailure]);

        const emailFieldMessages: FormFieldMessages = {
            error: {
                invalid: intl.formatMessage({
                    id: 'login__emailInvalid',
                    defaultMessage: 'Please enter a valid email address'
                }),
                empty: intl.formatMessage({
                    id: 'login__emptyEmail',
                    defaultMessage: 'Please provide email'
                })
            }
        };

        const passwordFieldMessages: FormFieldMessages = {
            hint: intl.formatMessage({
                id: 'login__passwordHint',
                defaultMessage:
                    '(Includes at least 8 characters, 1 uppercase, 1 lowercase and 1 number)'
            })
        };

        const forgotPasswordText = intl.formatMessage({
            id: 'login__forgotPassword',
            defaultMessage: 'Forgot password?'
        });

        const forgotPasswordLink = (
            <Link
                to="/forgotpassword"
                data-testid="forgotPasswordLink"
                className={clsx(
                    styles['login--passwordLink'],
                    styles['a-typography--body2']
                )}
            >
                {forgotPasswordText}
            </Link>
        );

        const formTitle = intl.formatMessage({
            id: 'login__formTitle',
            defaultMessage: 'Login'
        });
        const formDescription = intl.formatMessage({
            id: 'login__formDescription',
            defaultMessage: 'Welcome back!'
        });
        const submitBtnLabel = intl.formatMessage({
            id: 'login__submitButton',
            defaultMessage: 'Login'
        });

        const dontHaveAccountMsg = intl.formatMessage({
            id: 'login__noAccountMsg',
            defaultMessage: "Don't have an account?"
        });
        const dontHaveAccountSignUpMsg = intl.formatMessage({
            id: 'login__signUpMsg',
            defaultMessage: 'Sign up'
        });
        const dontHaveAccount = (
            <AccountMessageLink
                mainMessage={dontHaveAccountMsg}
                linkMessage={dontHaveAccountSignUpMsg}
                linkPath="/"
                className={styles['login--noAccount']}
                id="loginNoAccount"
            />
        );

        const loginErrorMsg = intl.formatMessage({
            id: 'login__error',
            defaultMessage:
                'Unable to login. Check your email, password and then try again.'
        });

        return (
            <>
                <UserAuthForm
                    id="loginForm"
                    formTitle={formTitle}
                    formDescription={formDescription}
                    email={email}
                    password={password}
                    onEmailChange={onEmailChange}
                    onPasswordChange={onPasswordChange}
                    emailFieldMessages={emailFieldMessages}
                    passwordFieldMessages={passwordFieldMessages}
                    submitBtnLabel={isLoading ? <Spinner /> : submitBtnLabel}
                    onValidationChange={onValidationChange}
                    submitBtnDisabled={!isFormValid || isLoading}
                    onSubmit={doLogin}
                    renderAdditionalPasswordMarkup={() => forgotPasswordLink}
                    renderFormFooter={() => dontHaveAccount}
                    renderExtraFormFields={() => (
                        <>
                            {showGeneralError && (
                                <Alert
                                    severity="danger"
                                    className={styles['login--alert']}
                                    text={
                                        <>
                                            <DangerIcon
                                                className={clsx(
                                                    styles['login--alert-icon'],
                                                    'a-icon a-icon--s'
                                                )}
                                            />
                                            {loginErrorMsg}
                                        </>
                                    }
                                />
                            )}
                        </>
                    )}
                />
                {storeRedirectForm}
            </>
        );
    },
    true,
    true
);
