export interface UserState {
    email: string;
    username: string;
    heap_external_id: string;
}

export const SET_EMAIL = 'SET_EMAIL';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_HEAP_EXTERNAL_ID = 'HEAP_EXTERNAL_ID';

export interface SetEmailAction {
    type: typeof SET_EMAIL;
    payload: string;
}

export interface SetUsernameAction {
    type: typeof SET_USERNAME;
    payload: string;
}

export interface SetHeapExternalId {
    type: typeof SET_HEAP_EXTERNAL_ID;
    payload: string;
}

export type UserActionTypes =
    | SetEmailAction
    | SetUsernameAction
    | SetHeapExternalId;
