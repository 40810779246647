import React, { useEffect } from 'react';
import { useQueryParams } from '../../hooks/useQueryParams/useQueryParams';
import { handleLogout } from '../../utils/sso-helpers';
// @ts-ignore
import { Loader } from '@workhuman/react-aurora-loader';
import { DEFAULT_DESTINATION } from '../../utils/constants';
import { useHistory } from 'react-router-dom';

export const Logout: React.FC = () => {
    const { idpName, destination, targetHost } = useQueryParams([
        'idpName',
        'destination',
        'targetHost'
    ]);
    const history = useHistory();

    useEffect(() => {
        handleLogout(
            idpName,
            destination || DEFAULT_DESTINATION,
            targetHost,
            history
        );
    }, [idpName, destination, targetHost, history]);

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Loader />
        </div>
    );
};
