import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

export interface CurrentCognitoUserResult {
    currentUser: CognitoUser | null;
    isLoadingUser: boolean;
}

export const useCurrentCognitoUser = (): CurrentCognitoUserResult => {
    const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setCurrentUser(user);
            })
            .catch(() => setCurrentUser(null))
            .finally(() => setIsLoading(false));
    }, []);

    return { currentUser, isLoadingUser: isLoading };
};
