import React from 'react';
import styles from './Spinner.module.scss';

export const Spinner = () => {
    return (
        <div className={styles['spinner-main']}>
            <span className={styles['spinner-content']} />
        </div>
    );
};
