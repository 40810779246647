import React, {useEffect, useState} from 'react';
import {handleLogoutRedirect} from '../../utils/sso-helpers';
// @ts-ignore
import {Loader} from '@workhuman/react-aurora-loader';
import {useQueryParams} from '../../hooks/useQueryParams/useQueryParams';
// @ts-ignore
import {InfoPanel} from '@workhuman/react-aurora-infopanel';

export const LogoutRedirect: React.FC = () => {
    const { client } = useQueryParams(['client']);

    const [logoutMessage, setLogoutMessage] = useState<string>('');

    useEffect(() => {
        handleLogoutRedirect(client, setLogoutMessage);
    }, [client]);

    const logoutPanel: JSX.Element = (
        <div>
            <InfoPanel description={`${logoutMessage}`} />
        </div>
    );

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {logoutMessage && logoutMessage.length > 0
                ? logoutPanel
                : <Loader />
            }
        </div>
    );
};
