import { defineMessages } from 'react-intl';

export default defineMessages({
    signUp: {
        id: 'pageTitle__signUp',
        defaultMessage: 'Sign up'
    },
    login: {
        id: 'pageTitle__login',
        defaultMessage: 'Login'
    },
    logout: {
        id: 'pageTitle__logout',
        defaultMessage: 'Logout'
    },
    logoutRedirect: {
        id: 'pageTitle__logout',
        defaultMessage: 'Logout'
    },
    forgotPassword: {
        id: 'pageTitle__forgotPassword',
        defaultMessage: 'Forgot password?'
    },
    error: {
        id: 'pageTitle__error',
        defaultMessage: 'Verification'
    },
    emailConfirmation: {
        id: 'pageTitle__emailConfirmation',
        defaultMessage: 'Confirm email'
    },
    resendConfirmation: {
        id: 'pageTitle__resendConfirmation',
        defaultMessage: 'Resend confirmation email'
    },
    confirmationSent: {
        id: 'pageTitle__confirmationSent',
        defaultMessage: 'Email sent'
    },
    invalidNotification: {
        id: 'pageTitle__invalidNotification',
        defaultMessage: 'Verification'
    },
    updatePassword: {
        id: 'pageTitle__updatePassword',
        defaultMessage: 'Update password'
    },
    instructionsSent: {
        id: 'pageTitle__instructionsSent',
        defaultMessage: 'Email sent'
    },
    resetRequired: {
        id: 'pageTitle__resetRequired',
        defaultMessage: 'Password reset required'
    },
    activateAccount: {
        id: 'pageTitle__activateAccount',
        defaultMessage: 'Activate account'
    },
    ssoInitializer: {
        id: 'pageTitle__ssoInit',
        defaultMessage: 'SSO Initialising'
    }
});
