import { LangLocaleState, SET_LANGUAGE, LangLocaleActionTypes } from './types';
import { DEFAULT_LANGUAGE } from '../../config/language/language-config';

export const initialState: LangLocaleState = {
    language: DEFAULT_LANGUAGE
};

export const langLocaleReducer = (
    state = initialState,
    action: LangLocaleActionTypes
): LangLocaleState => {
    switch (action.type) {
        case SET_LANGUAGE:
            return { ...state, language: action.payload };
        default:
            return state;
    }
};
