import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';

import { App } from './components/App/App';
import './index.scss';

Auth.configure({ clientMetadata: { target: 'retiree' } });
ReactDOM.render(<App />, document.getElementById('root'));
