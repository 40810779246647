import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Given an array ['one', 'two'] this type will look like:
 * { one: string, two: string }
 *
 * In the case of query params we can then do ['email', 'code']
 * and get a typed result:
 * { email: string, code: string }
 */
type QueryParamResult<K extends string> = {
    [P in K]: string;
};

export const useQueryParams = <K extends string>(
    paramKeys: K[]
): QueryParamResult<K> => {
    const location = useLocation();

    const parsedParams = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);

        const paramsWithKeyAndValue = paramKeys.reduce((result, key) => {
            return { ...result, [`${key}`]: queryParams.get(key) || '' };
        }, {}) as QueryParamResult<K>;

        return paramsWithKeyAndValue;
    }, [location, paramKeys]);

    return parsedParams;
};
