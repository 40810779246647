import React, { useMemo, useRef, useState, useCallback } from 'react';
import {
    STORE_DESTINATION_PATH,
    COGNITO_REDIRECT_URL
} from '../../utils/constants';
import { PersonProfile } from '../../utils/person-profile-parser';

export const useStoreRedirect = () => {
    const redirectFormRef = useRef() as React.MutableRefObject<HTMLFormElement>;
    const [redirectToken, setRedirectToken] = useState('');
    const [communityGuid, setCommunityGuid] = useState('');

    const goToStore = useCallback(
        (idToken: string, retireeProfile: PersonProfile) => {
            setRedirectToken(idToken);
            setCommunityGuid(retireeProfile.communityGuid);
            const actionUrl = `${COGNITO_REDIRECT_URL}?client=${retireeProfile.communityName}`;
            redirectFormRef.current.action = actionUrl;
            redirectFormRef.current.submit();
        },
        [redirectFormRef]
    );

    const storeRedirectForm = useMemo(
        () => (
            <form method="POST" ref={redirectFormRef}>
                <input type="hidden" name="idToken" value={redirectToken} />
                <input
                    type="hidden"
                    name="communityGuid"
                    value={communityGuid}
                />
                <input
                    type="hidden"
                    name="destinationURL"
                    value={STORE_DESTINATION_PATH}
                />
            </form>
        ),
        [redirectToken, communityGuid, redirectFormRef]
    );

    return { goToStore, storeRedirectForm };
};
