import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IDestination } from '../../SSOInitializer/SSOModels';
import { DESTINATION_LOGIN_TYPE_REDIRECT } from '../../utils/constants';

export const useDestinationRedirect = () => {
    // token-and-community:
    const tokenAndCommunityFormRef = useRef() as React.MutableRefObject<
        HTMLFormElement
    >;
    const [
        tokenAndCommunityFormToken,
        setTokenAndCommunityFormToken
    ] = useState('');
    const [
        tokenAndCommunityFormCommunity,
        setTokenAndCommunityFormCommunity
    ] = useState('');

    const tokenAndCommunityForm = useMemo(
        () => (
            <form method="POST" ref={tokenAndCommunityFormRef}>
                <input
                    type="hidden"
                    name="idToken"
                    value={tokenAndCommunityFormToken}
                />
                <input
                    type="hidden"
                    name="communityGuid"
                    value={tokenAndCommunityFormCommunity}
                />
            </form>
        ),
        [
            tokenAndCommunityFormToken,
            tokenAndCommunityFormCommunity,
            tokenAndCommunityFormRef
        ]
    );

    const submitTokenAndCommunityForm = useCallback(
        (destination: IDestination) => {
            setTokenAndCommunityFormToken(destination.idToken);
            setTokenAndCommunityFormCommunity(
                destination.personProfile
                    ? destination.personProfile.communityGuid
                    : ''
            );
            tokenAndCommunityFormRef.current.action = destination.loginURL;
            tokenAndCommunityFormRef.current.submit();
        },
        [tokenAndCommunityFormRef]
    );

    const goToDestination = useCallback(
        (destination: IDestination) => {
            console.log('Go To Destination:', destination.loginURL);

            if (destination.loginType === DESTINATION_LOGIN_TYPE_REDIRECT) {
                window.location.assign(destination.loginURL);
            } else {
                submitTokenAndCommunityForm(destination);
            }
        },
        [submitTokenAndCommunityForm]
    );

    return { goToDestination, tokenAndCommunityForm };
};
