import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { LangLocaleContextProvider } from '../../store';
import { UserContextProvider } from '../../store';
import { ApplicationMeta } from '../ApplicationMeta/ApplicationMeta';
import { PrivateRoute } from '../shared/PrivateRoute/PrivateRoute';
import { AmplifyConfigurator } from '../AmplifyConfigurator/AmplifyConfigurator';
import {
    APP_ROUTES,
    LOGOUT,
    LOGOUT_REDIRECT,
    SSO_INIT_WITH_PATH_PARAMS,
    SSO_INIT
} from '../../routes';
import { initHeap } from '../../utils/analytics';

import './App.scss';

export const App = () => {
    const [basename, setBasename] = useState('');

    const setAppBasename = () => {
        const isRetireeApp = window.location.pathname.startsWith('/retiree');
        const appBasename = isRetireeApp ? '/retiree' : '/identity';
        document.title = isRetireeApp ? 'Retiree Signup' : 'SSO Initialising';
        setBasename(appBasename);
    };

    const routes = APP_ROUTES.map(({ isPrivate, path, component }) => {
        return isPrivate ? (
            <PrivateRoute path={path} component={component} key={path} />
        ) : (
            <Route path={path} component={component} key={path} />
        );
    });

    useEffect(() => {
        setAppBasename();

        /*
         * Initialize heap analytics
         * Called every time when on application rendering with anonymous identifier
         * */
        initHeap();
    }, []);

    return (
        <LangLocaleContextProvider>
            <Router basename={basename}>
                <Switch>
                    <Route
                        path={SSO_INIT_WITH_PATH_PARAMS.path}
                        component={SSO_INIT_WITH_PATH_PARAMS.component}
                        key={SSO_INIT_WITH_PATH_PARAMS.path}
                    />

                    <Route
                        path={SSO_INIT.path}
                        component={SSO_INIT.component}
                        key={SSO_INIT.path}
                    />

                    <Route
                        path={LOGOUT.path}
                        component={LOGOUT.component}
                        key={LOGOUT.path}
                    />

                    <Route
                        path={LOGOUT_REDIRECT.path}
                        component={LOGOUT_REDIRECT.component}
                        key={LOGOUT_REDIRECT.path}
                    />

                    <AmplifyConfigurator>
                        <UserContextProvider>
                            <ApplicationMeta />
                            <Switch>{routes}</Switch>
                        </UserContextProvider>
                    </AmplifyConfigurator>
                </Switch>
            </Router>
        </LangLocaleContextProvider>
    );
};
