import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// @ts-ignore
import { addCustomProperties } from 'heap-analytics';
// @ts-ignore
import { Loader } from '@workhuman/react-aurora-loader';
import {
    useCurrentUser,
    setEmail,
    setUsername,
    setHeapExternalId
} from '../../store';
import { withLayout } from '../shared/withLayout/withLayout';
import { useQueryParams } from '../../hooks/useQueryParams/useQueryParams';
import { useLocalStorage } from '../../hooks/useLocalStorage/useLocalStorage';
import {
    VERIFICATION_CODE_EXPIRED,
    VERIFICATION_CODE_MISMATCH,
    NOT_AUTHORIZED
} from '../../utils/cognito-error-codes';

export const EmailConfirmation = withLayout(() => {
    const [, dispatch] = useCurrentUser();
    const history = useHistory();
    const { email, username, code, heap_external_id } = useQueryParams([
        'email',
        'username',
        'code',
        'heap_external_id'
    ]);
    const [isUserConfirmed, setStoredUserConfirmation] = useLocalStorage(
        'confirmed_userSub'
    );

    useEffect(() => {
        dispatch(setEmail(email));
        dispatch(setUsername(username));
        dispatch(setHeapExternalId(heap_external_id));
        Auth.confirmSignUp(username, code)
            .then(() => {
                setStoredUserConfirmation(username);
                history.push('/login');
            })
            .catch(err => {
                console.error('unable to confirm user email');
                if (err.code === VERIFICATION_CODE_EXPIRED) {
                    if (isUserConfirmed) {
                        /*
                         * If the user is confirmed and clicks verification link in email message -> navigate to Login page
                         * */
                        history.push('/login');
                    } else {
                        /*
                         * If the user is NOT confirmed and clicks verification link in email message after 24 hours -> navigate to Resend email page
                         * */
                        history.push('/resendconfirmation');
                    }
                } else if (err.code === NOT_AUTHORIZED) {
                    history.push('/login');
                } else if (err.code === VERIFICATION_CODE_MISMATCH) {
                    /*
                     *  If the user requested NEW verification email but clicks on link in OLD verification email (not expired, but obsolete)
                     * */
                    history.push('/invalidnotification');
                } else {
                    history.push('/error');
                }
            })
            .finally(() => {
                /*
                 * If user clicks confirmation email link with heap_external_id param, set custom user props to analytics
                 * */
                if (heap_external_id) {
                    const customDimensions = {
                        identityExternalId: heap_external_id
                    };

                    addCustomProperties(null, customDimensions);
                }
            });
    }, [dispatch, code, email, username]); // eslint-disable-line

    return (
        <div data-testid="emailConfirmation">
            <Loader />
        </div>
    );
}, false);
