import { defineMessages } from 'react-intl';

export default defineMessages({
    'en-us': {
        id: 'lang__en-us',
        defaultMessage: 'English (US)'
    },
    'es-es': {
        id: 'lang__es-es',
        defaultMessage: 'Español'
    },
    'es-419': {
        id: 'lang__es-419',
        defaultMessage: 'Español (América Latina)'
    }
});
