import { useCallback, useState } from 'react';

export const useLocalStorage = (
    key: string,
    initialValue?: string
): [string | undefined, (value: string) => void] => {
    const getItem = useCallback(() => {
        const item = localStorage.getItem(key);

        return item;
    }, [key]);

    const [value, setValue] = useState(() => {
        const item = getItem();

        return item ? item : initialValue;
    });

    const setItem = useCallback(
        (value: string) => {
            localStorage.setItem(key, value);
            setValue(value);
        },
        [key]
    );

    return [value, setItem];
};
