import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import {
    TextInput,
    TextInputLabel,
    TextInputGroup
    // @ts-ignore
} from '@workhuman/react-aurora-textinput';
// @ts-ignore
import { Button } from '@workhuman/react-aurora-button';
import { isEmailValid, isPasswordValid } from '../../../utils/utils';
import { UserAuthFormProps } from './types';
import styles from './UserAuthForm.module.scss';

export const UserAuthForm = ({
    formTitle,
    formDescription,
    onSubmit,
    email,
    hideEmail,
    onEmailChange,
    emailFieldMessages,
    password,
    onPasswordChange,
    hidePassword,
    passwordFieldMessages,
    submitBtnLabel,
    submitBtnDisabled,
    onValidationChange,
    renderAdditionalPasswordMarkup,
    renderExtraFormFields,
    renderFormFooter,
    id = 'userAuthForm'
}: UserAuthFormProps) => {
    const intl = useIntl();

    const [hasEmailError, setHasEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const [hasPasswordError, setHasPasswordError] = useState(false);

    useEffect(() => {
        if (onValidationChange) {
            let allFieldsValid = false;
            if (hideEmail && hidePassword) {
                allFieldsValid = true;
            } else if (hidePassword) {
                allFieldsValid = email !== undefined && isEmailValid(email);
            } else if (hideEmail) {
                allFieldsValid =
                    password !== undefined && isPasswordValid(password);
            } else {
                allFieldsValid =
                    email !== undefined &&
                    isEmailValid(email) &&
                    password !== undefined &&
                    isPasswordValid(password);
            }
            onValidationChange(allFieldsValid);
        }
    }, [onValidationChange, email, password, hidePassword, hideEmail]);

    const handleEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onEmailChange) {
                onEmailChange(event.target.value);
            }
        },
        [onEmailChange]
    );

    const validateEmail = useCallback(() => {
        if (email && !isEmailValid(email)) {
            setHasEmailError(true);
            if (emailFieldMessages.error) {
                setEmailErrorMessage(emailFieldMessages.error.invalid);
            }
        } else if (!email) {
            if (emailFieldMessages.error) {
                setEmailErrorMessage(emailFieldMessages.error.empty);
            }
            setHasEmailError(true);
        } else {
            setHasEmailError(false);
        }
    }, [email, emailFieldMessages]);

    const handlePasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onPasswordChange) {
                onPasswordChange(event.target.value);
            }
        },
        [onPasswordChange]
    );

    const validatePassword = useCallback(() => {
        if (password && !isPasswordValid(password)) {
            setHasPasswordError(true);
        } else if (!password) {
            setHasPasswordError(true);
        } else {
            setHasPasswordError(false);
        }
    }, [password]);

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(event);
    };

    return (
        <div className={styles['authFormBody']} data-testid={id}>
            <form onSubmit={handleFormSubmit} noValidate>
                <div className="a-typography--body1">{formTitle}</div>

                <h1
                    className={clsx(
                        styles['authForm-headline'],
                        'a-typography--headline1Bold'
                    )}
                >
                    {formDescription}
                </h1>

                {!hideEmail && (
                    <TextInput
                        isVertical={true}
                        className={clsx(
                            styles['input-responsive'],
                            styles['input-margin--topReset'],
                            'a-typography--body1'
                        )}
                        hasError={hasEmailError}
                    >
                        <TextInputLabel
                            htmlFor={'email'}
                            label={intl.formatMessage({
                                id: 'userAuthForm__emailLabel',
                                defaultMessage: 'Email'
                            })}
                        />
                        <TextInputGroup
                            data-testid={`${id}__emailInput`}
                            id="email"
                            name="email"
                            label={intl.formatMessage({
                                id: 'userAuthForm__emailLabel',
                                defaultMessage: 'Email'
                            })}
                            required={true}
                            placeholder="email@example.com"
                            value={email}
                            onChange={handleEmailChange}
                            maxLength="50"
                            autoComplete="off"
                            onBlur={validateEmail}
                        />
                        {hasEmailError && (
                            <span
                                data-testid={`${id}__emailInputError`}
                                className={styles['a-input-error']}
                            >
                                {emailErrorMessage}
                            </span>
                        )}
                    </TextInput>
                )}

                {!hidePassword && (
                    <TextInput
                        isVertical={true}
                        className={clsx(
                            styles['input-responsive'],
                            styles['input-margin--topReset'],
                            'a-typography--body1'
                        )}
                        hasError={hasPasswordError}
                    >
                        <div className={styles['authForm-password-label']}>
                            <TextInputLabel
                                htmlFor={'password'}
                                label={intl.formatMessage({
                                    id: 'userAuthForm__passwordLabel',
                                    defaultMessage: 'Password'
                                })}
                            />
                            {renderAdditionalPasswordMarkup &&
                                renderAdditionalPasswordMarkup()}
                        </div>
                        <TextInputGroup
                            data-testid={`${id}__passwordInput`}
                            id="password"
                            name="password"
                            label={intl.formatMessage({
                                id: 'userAuthForm__passwordLabel',
                                defaultMessage: 'Password'
                            })}
                            required={true}
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            minLength="8"
                            maxLength="50"
                            autoComplete="off"
                            onBlur={validatePassword}
                        />
                    </TextInput>
                )}

                <div
                    data-testid={`${id}__passwordHelperErrorText`}
                    className={clsx(
                        styles['password-helper--text'],
                        hasPasswordError &&
                            styles['password-helper--errorText'],
                        'a-typography--body2',
                        !renderExtraFormFields && styles['input-margin--bottom']
                    )}
                >
                    <span>{passwordFieldMessages.hint}</span>
                </div>

                {renderExtraFormFields && renderExtraFormFields()}

                <Button
                    data-testid={`${id}__submitBtn`}
                    id="submitBtn"
                    label={submitBtnLabel}
                    variant="primary"
                    type="submit"
                    className={styles['input-responsive']}
                    disabled={submitBtnDisabled}
                >
                    {submitBtnLabel}
                </Button>

                {renderFormFooter && renderFormFooter()}
            </form>
        </div>
    );
};
