export interface LangLocaleState {
    language: string;
}

export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface SetLanguageAction {
    type: typeof SET_LANGUAGE;
    payload: string;
}

export type LangLocaleActionTypes = SetLanguageAction;
