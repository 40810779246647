import React from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './AccountMessageLink.module.scss';

interface AlreadyHaveAccountProps {
    className?: string;
    mainMessage?: string;
    linkMessage?: string;
    linkPath?: string;
    id?: string;
}

export const AccountMessageLink = ({
    className,
    mainMessage,
    linkMessage,
    linkPath = '/login',
    id = 'accountMessageLink'
}: AlreadyHaveAccountProps) => {
    const intl = useIntl();

    const defaultMainMessage = intl.formatMessage({
        id: 'alreadyHaveAccount__description',
        defaultMessage: 'Already have an account?'
    });

    const defaultLinkMessage = intl.formatMessage({
        id: 'alreadyHaveAccount__login',
        defaultMessage: 'Login'
    });

    return (
        <div
            data-testid={id}
            className={clsx(styles['alreadyHaveAccount'], className)}
        >
            <span
                data-testid={`${id}__mainMessage`}
                className={clsx(
                    styles['alreadyHaveAccount--text'],
                    'a-typography--body2'
                )}
            >
                {mainMessage ? mainMessage : defaultMainMessage}
            </span>
            <Link
                className={styles['alreadyHaveAccount--link']}
                to={linkPath}
                data-testid={`${id}__link`}
            >
                {linkMessage ? linkMessage : defaultLinkMessage}
            </Link>
        </div>
    );
};
