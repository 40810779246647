import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { getClientMetadata } from '../../utils/utils';

export const useForgotPassword = (
    redirectOnSuccessPath: string,
    redirectOnErrorPath = '/error',
    navigateToSuccess?: () => void,
    navigateToFailed?: () => void
) => {
    const history = useHistory();

    const handleForgotPassword = useCallback(
        (email: string, extraMetadata = getClientMetadata()) => {
            Auth.forgotPassword(email, extraMetadata)
                .then(() => {
                    navigateToSuccess
                        ? navigateToSuccess()
                        : history.push(redirectOnSuccessPath);
                })
                .catch(() => {
                    navigateToFailed
                        ? navigateToFailed()
                        : history.push(redirectOnErrorPath);
                });
        },
        [
            history,
            redirectOnSuccessPath,
            redirectOnErrorPath,
            navigateToSuccess,
            navigateToFailed
        ]
    );

    return handleForgotPassword;
};
