import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
    CognitoIdToken,
    CognitoUser,
    CognitoUserSession
} from 'amazon-cognito-identity-js';
import {
    parsePersonProfiles,
    PersonProfile
} from '../utils/person-profile-parser';

import { useQueryParams } from '../hooks/useQueryParams/useQueryParams';
// @ts-ignore
import {
    calcClient,
    calcDestination,
    calcIdpName,
    calcTarget,
    calcTargetHost,
    cleanupSessionStorage,
    configureAmplify,
    getSSOTimeoutRetryAttempt,
    hasCodeBuildIdMismatch,
    loadEnvConfig,
    loadRemoteConfig,
    logAmplifyLifecycleEvents,
    setPageTitle,
    setSSOTimeoutRetryAttempt,
    sendErrorToBackend
} from '../utils/utils';
import axios from 'axios';
import {
    DESTINATION_LOGIN_TYPE_TOKEN_AND_COMMUNITY,
    PERSON_ACTIVATION_URL
} from '../utils/constants';
import { OutgoingHttpHeaders } from 'http';
import styles from '../components/Error/Error.module.scss';
// @ts-ignore
import { ReactComponent as WarningIcon } from '../components/Error/warning-line.svg';
// @ts-ignore
import { InfoPanel } from '@workhuman/react-aurora-infopanel';
import { IDestination, IEnvConfig, IRedirectConfig, Stage } from './SSOModels';
import { useDestinationRedirect } from '../hooks/useDestinationRedirect/useDestinationRedirect';

const NUM_SSO_TIMEOUT_RETRIES: number = 3;

const makeAPICall = async (
    endpoint: string,
    token: string,
    body: any
): Promise<void> => {
    const headers: OutgoingHttpHeaders = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Authorization-type': 'cognito'
    };
    console.log('making API call:', endpoint);
    await axios.post(endpoint, { ...body }, { headers });
};

const activatePersonProfile = async (
    currentSession: CognitoUserSession,
    client: string,
    targetHost?: string
): Promise<boolean> => {
    let newProfileCreated = false;

    const idToken = currentSession.getIdToken();
    const personProfile = getPersonProfile(idToken, client);

    console.log('person activation required:', personProfile == null);

    if (!personProfile) {
        console.log('activating person profile');
        let url = `${PERSON_ACTIVATION_URL}`;
        if (targetHost) {
            url = `${targetHost}${url}`;
        }
        const jwtToken = idToken.getJwtToken();

        try {
            await makeAPICall(url, jwtToken, {
                clientName: client
            });
        } catch (error) {
            let errorMsg = `Activating user `;
            let providerIdentities = idToken.payload.identities;
            if (providerIdentities) {
                console.log('provider identity', providerIdentities);
                errorMsg += providerIdentities[0].userId;
            }
            throw new Error((errorMsg += ` on ${new Date()}`));
        }
        newProfileCreated = true;
    }
    return newProfileCreated;
};

const handleRedirect = (
    idToken: CognitoIdToken,
    client: string,
    envConfig: IEnvConfig,
    redirectConfig: IRedirectConfig,
    goToDestination: (destination: IDestination) => void,
    setErrorMessage: (error: string) => void
): void => {
    console.log('making redirect to destination:', redirectConfig.destination);
    const finalDestination = envConfig.destinations[redirectConfig.destination];

    if (
        finalDestination.loginType ===
        DESTINATION_LOGIN_TYPE_TOKEN_AND_COMMUNITY
    ) {
        finalDestination.loginURL = `${finalDestination.loginURL}?client=${client}`;
        if (redirectConfig.TARGET) {
            finalDestination.loginURL = `${
                finalDestination.loginURL
            }&TARGET=${encodeURIComponent(redirectConfig.TARGET)}`;
        }
        if (redirectConfig.targetHost) {
            finalDestination.loginURL = `${redirectConfig.targetHost}${finalDestination.loginURL}`;
        }

        const personProfile = getPersonProfile(idToken, client);
        if (personProfile) {
            finalDestination.personProfile = personProfile;
            console.log('active community:', personProfile.communityName);
        } else {
            console.log('No person in the token for ', client);
            setErrorMessage('No person in the token.');
            return;
        }
    }

    cleanupSessionStorage();

    finalDestination.idToken = idToken.getJwtToken();
    goToDestination(finalDestination);
};

const getPersonProfile = (
    idToken: CognitoIdToken,
    client: string
): PersonProfile | undefined => {
    const personProfiles: PersonProfile[] = parsePersonProfiles(
        JSON.parse(JSON.stringify(idToken.payload)).person_profiles
    );
    return personProfiles.find(
        (profile: PersonProfile) => profile.communityName === client
    );
};

const handleLogin = async (
    idpName: string,
    authConfig: string
): Promise<void> => {
    console.log('calling federatedSignIn for idpName:', idpName);
    await Auth.federatedSignIn({
        customProvider: idpName,
        customState: authConfig
    });
};

const handleTokenRefreshAndRedirect = async (
    newProfileCreated: boolean,
    currentSession: CognitoUserSession,
    client: string,
    envConfig: IEnvConfig,
    redirectConfig: IRedirectConfig,
    goToDestination: (destination: IDestination) => void,
    setErrorMessage: (error: string) => void
): Promise<void> => {
    if (newProfileCreated) {
        console.log('calling refresh and then redirect');
        const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
        currentUser.refreshSession(
            currentSession.getRefreshToken(),
            (error, newSession: CognitoUserSession) => {
                if (error) {
                    console.log('Unable to refresh Token:', error);
                    setErrorMessage(error);
                } else {
                    handleRedirect(
                        newSession.getIdToken(),
                        client,
                        envConfig,
                        redirectConfig,
                        goToDestination,
                        setErrorMessage
                    );
                }
            }
        );
    } else {
        console.log('calling redirect without refresh');
        handleRedirect(
            currentSession.getIdToken(),
            client,
            envConfig,
            redirectConfig,
            goToDestination,
            setErrorMessage
        );
    }
};

const handleLoginSuccess = async (
    client: string,
    envConfig: IEnvConfig,
    redirectConfig: IRedirectConfig,
    goToDestination: (destination: IDestination) => void,
    setErrorMessage: (error: string) => void
): Promise<void> => {
    const currentSession = await Auth.currentSession();

    let newProfileCreated = false;
    const finalDestination = envConfig.destinations[redirectConfig.destination];
    if (
        finalDestination.loginType ===
        DESTINATION_LOGIN_TYPE_TOKEN_AND_COMMUNITY
    ) {
        newProfileCreated = await activatePersonProfile(
            currentSession,
            client,
            redirectConfig.targetHost
        );
    } else {
        console.log(
            'Activation is not required for destination: ' +
                redirectConfig.destination
        );
    }

    await handleTokenRefreshAndRedirect(
        newProfileCreated,
        currentSession,
        client,
        envConfig,
        redirectConfig,
        goToDestination,
        setErrorMessage
    );
};

const handleLoginSequence = async (
    client: string,
    idpName: string,
    stage: Stage,
    state: string,
    redirectConfig: IRedirectConfig,
    goToDestination: (destination: IDestination) => void,
    setErrorMessage: (error: string) => void
): Promise<void> => {
    try {
        const envConfig = await loadEnvConfig();
        const remoteConfig = await loadRemoteConfig(envConfig, idpName, state);
        const rawAuthConfig = JSON.stringify(remoteConfig);

        redirectConfig.targetHost = calcTargetHost(
            envConfig.allowCustomTargetHost,
            redirectConfig.targetHost
        );

        await configureAmplify(remoteConfig);

        if (stage === Stage.LOGIN) {
            console.log('login stage, idpName:', idpName);

            await handleLogin(idpName, rawAuthConfig);
        } else {
            console.log('redirect stage, idpName:', idpName);
            await handleLoginSuccess(
                client,
                envConfig,
                redirectConfig,
                goToDestination,
                setErrorMessage
            );
        }
    } catch (error) {
        console.log(error);
        const errorMessage = error.message ? error.message : error;
        setErrorMessage(errorMessage);
    }
};

const retrySSOInit = (
    client: string | null,
    idpType: string | null,
    clientPathParam: string | null,
    setErrorMessage: (error: string) => void
): void => {
    let retryAttempt: number = 0;
    const sessionAttempt = getSSOTimeoutRetryAttempt();
    if (sessionAttempt) {
        retryAttempt = Number(sessionAttempt);
    }
    if (retryAttempt < NUM_SSO_TIMEOUT_RETRIES) {
        const reloadMsg = 'Timeout - reloading...';
        console.log(reloadMsg);
        let refreshUrl = window.location.pathname;
        let randomParam = Math.random();
        if (!clientPathParam) {
            refreshUrl = `${refreshUrl}?client=${client}&idpType=${idpType}&nocache=${randomParam}`;
        } else {
            refreshUrl = `${refreshUrl}?nocache=${randomParam}`;
        }

        setSSOTimeoutRetryAttempt(retryAttempt + 1);
        window.location.assign(refreshUrl);
    } else {
        setErrorMessage('Timeout - reload attempts exceeded!');
    }
};

const isFederatedSignIn = (idpType: string): boolean => {
    return idpType.toLowerCase() !== 'username';
};

export const SSOInitializer: React.ComponentType<object> = () => {
    const { clientPathParam, idpTypePathParam } = useParams<{
        clientPathParam: string;
        idpTypePathParam: string;
    }>();

    const {
        client,
        idpType,
        idpName,
        code,
        state,
        targetURL,
        targetHost,
        TARGET,
        destination,
        error,
        error_description
    } = useQueryParams([
        'client',
        'idpType',
        'idpName',
        'code',
        'state',
        'targetURL',
        'targetHost',
        'TARGET',
        'destination',
        'error',
        'error_description'
    ]);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const { goToDestination, tokenAndCommunityForm } = useDestinationRedirect();

    setPageTitle('SSO Initialising');

    useEffect(() => {
        logAmplifyLifecycleEvents();

        const stage = !code ? Stage.LOGIN : Stage.CODE;

        const pkceKey = sessionStorage.getItem('ouath_pkce_key');
        console.log(pkceKey ? 'pkce key exists' : 'no pkce key');

        const codeBuildMismatch = hasCodeBuildIdMismatch(stage);
        const resolvedIdpType = idpTypePathParam || idpType;

        if (error) {
            setErrorMessage(error_description ? error_description : error);
        } else if (
            stage === Stage.CODE &&
            (!state || !pkceKey || codeBuildMismatch) &&
            isFederatedSignIn(resolvedIdpType)
        ) {
            retrySSOInit(client, idpType, clientPathParam, setErrorMessage);
        } else {
            const clientToUse = calcClient(
                clientPathParam ? clientPathParam : client
            );
            const idpNameToUse = calcIdpName(
                idpName,
                resolvedIdpType,
                clientToUse
            );

            const redirectConfig: IRedirectConfig = {
                destination: calcDestination(destination),
                TARGET: calcTarget(TARGET),
                targetHost: targetHost ? targetHost : targetURL
            };

            handleLoginSequence(
                clientToUse,
                idpNameToUse,
                stage,
                state,
                redirectConfig,
                goToDestination,
                setErrorMessage
            );
        }
    }, [
        client,
        clientPathParam,
        idpType,
        idpTypePathParam,
        idpName,
        code,
        state,
        targetURL,
        targetHost,
        TARGET,
        destination,
        error,
        error_description,
        goToDestination
    ]);

    const errorPanel: JSX.Element = (
        <div className={styles['error']} data-testid="ssoErrorPanel">
            <WarningIcon className="a-icon a-icon--iconColor a-icon--xxl" />
            <InfoPanel heading={'SSO Error'} description={`${errorMessage}`} />
            <div id="uuid" />
        </div>
    );

    if (errorMessage && errorMessage.length > 0) {
        sendErrorToBackend(
            errorMessage,
            clientPathParam ? clientPathParam : client,
            code
        ).then(response => {
            console.log('Server Error UUID: ' + response);
            // manually do this to avoid triggering a re-render
            var uuidElement = document.getElementById('uuid');
            if (uuidElement) {
                uuidElement.innerHTML = response;
            }
        });
    }

    return (
        <div data-testid="ssoPageContainer">
            {errorMessage && errorMessage.length > 0 && errorPanel}
            {tokenAndCommunityForm}
        </div>
    );
};
