import { Auth } from 'aws-amplify';
import {
    cleanupSessionStorage,
    configureAmplify,
    getLogoutURL,
    loadEnvConfig,
    loadRemoteConfig,
    setLogoutURL
} from './utils';
import { AwsCognitoOAuthOpts } from '@aws-amplify/auth/lib-esm/types/Auth';
import * as H from 'history';
import { LOGOUT_REDIRECT } from '../routes';

export const handleLogout = async (
    idpName: string,
    destination: string,
    targetHost: string | null,
    history: H.History<any>
): Promise<void> => {
    const envConfig = await loadEnvConfig();
    const remoteConfig = await loadRemoteConfig(envConfig, idpName, null);
    const amplifyConfig = await configureAmplify(remoteConfig);

    const finalDestination = envConfig.destinations[destination];
    setLogoutURL((targetHost || '') + finalDestination.logoutURL);

    console.log('calling signOut with idpName:', idpName);
    await Auth.signOut();

    try {
        let currentSession = await Auth.currentSession();
        console.log('current user found after signOut', currentSession);
    } catch (error) {
        console.log('error thrown during signOut:', error);

        if (error === 'No current user') {
            if (amplifyConfig?.oauth) {
                const url = new URL(
                    `https://${amplifyConfig.oauth?.domain}/logout`
                );

                url.searchParams.append(
                    'client_id',
                    amplifyConfig.userPoolWebClientId as string
                );
                url.searchParams.append(
                    'logout_uri',
                    (amplifyConfig.oauth as AwsCognitoOAuthOpts).redirectSignOut
                );

                console.log('No current user found, redirecting to:', url.href);
                window.location.href = url.href;
            } else {
                const clientName = idpName.split('-')[1];
                const url = `${LOGOUT_REDIRECT.path}?client=${clientName}`;
                console.log(
                    'No current user found, bypass oauth redirect and navigate to:',
                    url
                );
                history.push(url);
            }
        }
    }
};

export const handleLogoutRedirect = (
    client: string,
    setLogoutMessage: (logoutMessage: string) => void
): void => {
    let logoutURL = getLogoutURL();

    cleanupSessionStorage();

    if (logoutURL) {
        if (client) {
            logoutURL =
                logoutURL.indexOf('?') === -1
                    ? `${logoutURL}?client=${client}`
                    : `${logoutURL}&client=${client}`;
        }
        console.log('Redirecting to logout URL:', logoutURL);
        window.location.href = logoutURL;
    } else {
        console.log('No logout URL in the Session Storage to redirect.');
        setLogoutMessage('You have successfully logged out');
    }
};
