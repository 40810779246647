import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useLangLocale } from '../../store';
import { findRouteByPath } from '../../routes';

export const ApplicationMeta = () => {
    const intl = useIntl();
    const location = useLocation();
    const [{ language }] = useLangLocale();

    const defaultTitleMsg = intl.formatMessage({
        id: 'app_defaultTitle',
        defaultMessage: 'Retiree Signup'
    });

    const titleMsg = useMemo(() => {
        const currentRoute = findRouteByPath(location.pathname);
        if (currentRoute) {
            const { title } = currentRoute;
            if (title) {
                return intl.formatMessage(title);
            }

            return defaultTitleMsg;
        }

        return defaultTitleMsg;
    }, [intl, defaultTitleMsg, location]);

    return (
        <Helmet>
            <title>{titleMsg}</title>
            <html lang={language} />
        </Helmet>
    );
};
