export const EMAIL_PATTERN = /^([a-zA-Z0-9\-_+]+(\.[a-zA-Z0-9\-_+]+)*)@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])^([a-zA-Z0-9^$*.[\]{}()?"!@#%&\\/,><':;|_~`+-]{8,})$/;

export const COGNITO_REDIRECT_URL = '/microsites/motivation/CognitoEntryPoint';
export const STORE_DESTINATION_PATH = '/microsites/t/awards/Redeem';
export const CLIENT_ERROR_BACKEND_URL = '/microsites/t_pub/jserror';

export const CLIENT_METADATA_ADDITIONAL_PARAMS = {
    key_target: 'target',
    value_target: 'retiree',
    key_repeated_signup: 'repeatedSignup',
    value_repeated_signup: 'true',
    key_lang: 'lang'
};
export const IDENTITY_CONFIG_URL = '/retiree/env-props/properties.json?v15';
export const IDENTITY_OAUTH_URL =
    '/api/v2/oauth-config/find-oauth-config-by-idp-name/';

export const RETIREE_ACTIVATION_URL = '/client-service-app/v2/retiree/activate';
export const PERSON_ACTIVATION_URL = '/client-service-app/v2/persons/activate';

export const EMPLOYEE_ID_LENGTH = 9;
export const ACCESS_CODE_LENGTH = 8;

export const DEFAULT_DESTINATION = 'microsites';

export const DESTINATION_LOGIN_TYPE_TOKEN_AND_COMMUNITY = 'token-and-community';
export const DESTINATION_LOGIN_TYPE_TOKEN = 'token';
export const DESTINATION_LOGIN_TYPE_REDIRECT = 'redirect';
