import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
    TextInput,
    TextInputLabel,
    TextInputGroup
    // @ts-ignore
} from '@workhuman/react-aurora-textinput';
import { Spinner } from '../shared/Spinner/Spinner';
import { withLayout } from '../shared/withLayout/withLayout';
import { useQueryParams } from '../../hooks/useQueryParams/useQueryParams';
import { UserAuthForm } from '../shared/UserAuthForm/UserAuthForm';
import { FormFieldMessages } from '../shared/UserAuthForm/types';
import { useCurrentUser, setEmail } from '../../store';
import styles from './UpdatePassword.module.scss';

export const UpdatePassword = withLayout(() => {
    const intl = useIntl();
    const history = useHistory();
    const [, dispatch] = useCurrentUser();
    const { email, code } = useQueryParams(['email', 'code']);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasPasswordConfirmError, setHasPasswordConfirmError] = useState(
        false
    );

    const isConfirmPasswordValid = useMemo(() => {
        return confirmPassword === password;
    }, [confirmPassword, password]);

    useEffect(() => {
        setIsFormValid(isPasswordValid && isConfirmPasswordValid);
    }, [isPasswordValid, isConfirmPasswordValid]);

    useEffect(() => {
        dispatch(setEmail(email));
    }, [dispatch, email]);

    const onValidationChange = useCallback((allFieldsValid: boolean) => {
        setIsPasswordValid(allFieldsValid);
    }, []);

    const onPasswordChange = useCallback(
        (newPassword: string) => setPassword(newPassword),
        [setPassword]
    );

    const validateConfirmPassword = useCallback(() => {
        const isValid = isConfirmPasswordValid;
        setHasPasswordConfirmError(!isValid);
    }, [isConfirmPasswordValid]);

    const handleConfirmPasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value);
        },
        []
    );

    const doUpdatePassword = useCallback(() => {
        setIsLoading(true);
        Auth.forgotPasswordSubmit(email, code, password)
            .then(() => history.push('/login'))
            .catch(() => history.push('/error'))
            .finally(() => setIsLoading(false));
    }, [code, email, password, history]);

    const formTitle = intl.formatMessage({
        id: 'updatePassword__title',
        defaultMessage: 'Update password'
    });
    const formDescription = intl.formatMessage({
        id: 'updatePassword__description',
        defaultMessage: "You're nearly there."
    });
    const submitBtnLabel = intl.formatMessage({
        id: 'updatePassword__submit',
        defaultMessage: 'Update password'
    });

    const passwordFieldMessages: FormFieldMessages = {
        hint: intl.formatMessage({
            id: 'updatePassword__passwordHint',
            defaultMessage:
                'Must be at least 8 characters, 1 uppercase, 1 lowercase and 1 number'
        })
    };

    const confirmPasswordLabel = intl.formatMessage({
        id: 'updatePasswordForm__confirmPasswordLabel',
        defaultMessage: 'Confirm password'
    });

    const confirmPasswordErrorMsg = intl.formatMessage({
        id: 'updatePasswordForm__confirmPasswordError',
        defaultMessage: 'Confirmation does not match password.'
    });

    const confirmPasswordField = (
        <>
            <TextInput
                isVertical={true}
                className={clsx(
                    styles['input-responsive'],
                    styles['input-margin--topReset'],
                    !hasPasswordConfirmError && styles['input-margin--bottom'],
                    'a-typography--body1'
                )}
                hasError={hasPasswordConfirmError}
            >
                <TextInputLabel
                    htmlFor={'confirmPassword'}
                    label={confirmPasswordLabel}
                />

                <TextInputGroup
                    data-testid={`updatePasswordForm__confirmPasswordInput`}
                    id="confirmPassword"
                    name="confirmPassword"
                    label={confirmPasswordLabel}
                    required={true}
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    minLength="8"
                    maxLength="50"
                    autoComplete="off"
                    onBlur={validateConfirmPassword}
                />
            </TextInput>
            {hasPasswordConfirmError && (
                <span
                    data-testid={`updatePasswordForm__confirmPasswordInputError`}
                    className={clsx(
                        styles['a-input-error'],
                        styles['input-margin--bottom']
                    )}
                >
                    {confirmPasswordErrorMsg}
                </span>
            )}
        </>
    );

    return (
        <UserAuthForm
            id="updatePasswordForm"
            formTitle={formTitle}
            formDescription={formDescription}
            hideEmail
            emailFieldMessages={{}}
            password={password}
            onPasswordChange={onPasswordChange}
            passwordFieldMessages={passwordFieldMessages}
            submitBtnLabel={isLoading ? <Spinner /> : submitBtnLabel}
            onValidationChange={onValidationChange}
            submitBtnDisabled={!isFormValid || isLoading}
            onSubmit={doUpdatePassword}
            renderExtraFormFields={() => confirmPasswordField}
        />
    );
});
