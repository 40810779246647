import React, { FunctionComponent, useMemo } from 'react';
import { Authenticator } from 'aws-amplify-react';
import { useLangLocale } from '../../store';
import { getClientMetadata } from '../../utils/utils';
import { CLIENT_METADATA_ADDITIONAL_PARAMS } from '../../utils/constants';
import amplifyConfig from '../../config/aws-config';

export const AmplifyConfigurator: FunctionComponent = ({ children }) => {
    const [{ language }] = useLangLocale();

    const clientMetadata = useMemo(() => {
        return getClientMetadata({
            [`${CLIENT_METADATA_ADDITIONAL_PARAMS.key_lang}`]: language
        });
    }, [language]);

    const finalAmplifyConfig = useMemo(
        () => ({ ...amplifyConfig, clientMetadata }),
        [clientMetadata]
    );

    return (
        <Authenticator hideDefault={true} amplifyConfig={finalAmplifyConfig}>
            {children}
        </Authenticator>
    );
};
