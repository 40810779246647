import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
// @ts-ignore
import { Loader } from '@workhuman/react-aurora-loader';
import { useCurrentCognitoUser } from '../../../hooks/useCurrentCognitoUser/useCurrentCognitoUser';

export const PrivateRoute: FunctionComponent<RouteProps> = ({
    component: Component,
    ...routeProps
}) => {
    const { currentUser, isLoadingUser } = useCurrentCognitoUser();

    return (
        <Route
            {...routeProps}
            render={props => {
                if (isLoadingUser) {
                    return <Loader />;
                }

                return !!currentUser ? (
                    // @ts-ignore
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                );
            }}
        />
    );
};
