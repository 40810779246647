import { useCallback } from 'react';
import { useCurrentCognitoUser } from '../useCurrentCognitoUser/useCurrentCognitoUser';
import { useCurrentSession } from '../useCurrentSession/useCurrentSession';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const useRefreshCognitoSession = () => {
    const { currentUser } = useCurrentCognitoUser();
    const currentSession = useCurrentSession();

    return useCallback(() => {
        return new Promise<CognitoUserSession>((resolve, reject) => {
            if (currentUser && currentSession) {
                currentUser.refreshSession(
                    currentSession.getRefreshToken(),
                    (err, newSession: CognitoUserSession) => {
                        if (err) {
                            reject(err);
                            return;
                        }

                        resolve(newSession);
                    }
                );
            } else {
                reject(
                    'Unable to find current user or current session in order to refresh session'
                );
            }
        });
    }, [currentUser, currentSession]);
};
