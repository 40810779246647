import React from 'react';
import { ReactComponent as WHLogoSvg } from '../../../assets/wh-logo.svg';
import { AuthWelcome } from '../../AuthWelcome/AuthWelcome';
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import './withLayout.scss';

export function withLayout<P extends object>(
    Component: React.ComponentType<P>,
    showSidePanel = true,
    showLanguagePicker = false
): React.ComponentType<P> {
    const containerClass = showSidePanel
        ? 'app-auth-container-with-side-panel'
        : 'app-auth-container';

    const childComponent = (props: P) => <Component {...props} />;

    return (props: P) => (
        <div data-testid="withLayout" className="identity-app-root">
            <div className={containerClass}>
                <header className="app-auth-header">
                    <div className="app-auth-logo">
                        <WHLogoSvg className="app-auth-logo--svg" />
                    </div>
                </header>
                <section className="app-auth-section">
                    <div className="app-auth-form-container">
                        <div className="auth-app-form">
                            {childComponent(props)}
                        </div>
                    </div>
                </section>

                <div className="app-auth-language">
                    {showLanguagePicker && <LanguagePicker />}
                </div>
            </div>
            {showSidePanel && <AuthWelcome />}
        </div>
    );
}
