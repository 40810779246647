export const APPLICATION_COMMUNITY_TYPE = 'APPLICATION';
export const RETIREE_COMMUNITY_TYPE = 'RETIREE';

type CommunityType =
    | typeof APPLICATION_COMMUNITY_TYPE
    | typeof RETIREE_COMMUNITY_TYPE;

export interface PersonProfile {
    guid: string;
    client: string;
    permission: string;
    person: string;
    communityGuid: string;
    communityType: CommunityType;
    communityName: string;
}

export const parsePersonProfiles = (
    rawPersonProfiles: string
): Array<PersonProfile> => {
    const escaped = rawPersonProfiles
        .replace(/'/g, '"')
        .replace(/None/g, 'null');
    return JSON.parse(escaped);
};

export const hasRetireeVerifiedAccount = (rawPersonProfiles: string) => {
    const personProfiles = parsePersonProfiles(rawPersonProfiles);
    return !!personProfiles.find(
        profile => profile.communityType === RETIREE_COMMUNITY_TYPE
    );
};

export const getRetireePersonProfile = (rawPersonProfiles: string) => {
    const personProfiles = parsePersonProfiles(rawPersonProfiles);
    return personProfiles.find(
        profile => profile.communityType === RETIREE_COMMUNITY_TYPE
    );
};
