import { useCallback, useMemo } from 'react';
import axios from 'axios';
import { useCurrentSession } from '../../hooks/useCurrentSession/useCurrentSession';
import { RETIREE_ACTIVATION_URL } from '../../utils/constants';

export const useAccountActivationRequest = () => {
    const currentUser = useCurrentSession();

    const getAuthorizationToken = useCallback(() => {
        if (currentUser) {
            return `Bearer ${currentUser.getIdToken().getJwtToken()}`;
        }

        return null;
    }, [currentUser]);

    const headers = useMemo(() => {
        return {
            Authorization: getAuthorizationToken(),
            'Content-Type': 'application/json',
            'Authorization-type': 'cognito'
        };
    }, [getAuthorizationToken]);

    return useCallback(
        (employeeId: string, accessCode: string) => {
            return axios.post(
                RETIREE_ACTIVATION_URL,
                { employeeId, accessCode },
                {
                    headers
                }
            );
        },
        [headers]
    );
};
