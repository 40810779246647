import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// @ts-ignore
import { Button } from '@workhuman/react-aurora-button';
import { useCurrentUser } from '../../store';
import { AccountMessageLink } from '../shared/AccountMessageLink/AccountMessageLink';
import { Spinner } from '../shared/Spinner/Spinner';
import { withLayout } from '../shared/withLayout/withLayout';
import styles from './ResendConfirmation.module.scss';

export const ResendConfirmation = withLayout(() => {
    const intl = useIntl();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [{ email }] = useCurrentUser();

    const resendConfirmation = useCallback(() => {
        setIsLoading(true);
        Auth.resendSignUp(email)
            .then(() => {
                setIsLoading(false);
                history.push('/confirmationsent');
            })
            .catch(() => {
                setIsLoading(false);
                history.push('/error');
            });
    }, [history, email]);

    const pageHeading = intl.formatMessage({
        id: 'resendConfirmation__heading',
        defaultMessage: "We just need to make sure it's you"
    });

    const pageDescription = intl.formatMessage({
        id: 'resendConfirmation__description',
        defaultMessage:
            "We've sent you an email. You'll need to click the verification link inside it so that you can login."
    });

    const resendMessage = intl.formatMessage({
        id: 'resendConfirmation__resend',
        defaultMessage: 'Resend verification email'
    });

    return (
        <section
            className={styles['resendConfirmation']}
            data-testid="resendConfirmation"
        >
            <h1 className="a-typography--headline1Bold">{pageHeading}</h1>
            <span
                className={clsx(
                    styles['resendConfirmation--description'],
                    'a-typography--body1'
                )}
            >
                {pageDescription}
            </span>

            <div>
                <Button
                    variant="primary"
                    onClick={resendConfirmation}
                    data-testid="sendConfirmationBtn"
                    className={styles['resendConfirmation--button']}
                >
                    {isLoading ? <Spinner /> : resendMessage}
                </Button>
                <AccountMessageLink />
            </div>
        </section>
    );
}, false);
