import React from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import styles from './AuthWelcome.module.scss';

export const AuthWelcome = () => {
    const intl = useIntl();

    return (
        <div
            data-testid="authWelcome"
            className={clsx(
                styles['auth-welcome-container'],
                styles['auth-welcome-bg']
            )}
        >
            <div className={styles['auth-welcome-container--message']}>
                <h1 className={styles['a-typography--headline1Bold']}>
                    <div className={styles['auth-welcome-heading']}>
                        {intl.formatMessage({
                            id: 'welcome_headline_topline',
                            defaultMessage: 'Best wishes on your'
                        })}
                    </div>
                    <div className={styles['auth-welcome-heading']}>
                        {intl.formatMessage({
                            id: 'welcome_headline_secondline',
                            defaultMessage: 'next adventure'
                        })}
                    </div>
                </h1>
                <div className={styles['a-typography--body1']}>
                    <p>
                        {intl.formatMessage({
                            id: 'welcome_congrats_firstline',
                            defaultMessage:
                                'Congratulations on your retirement! Wishing you all the best'
                        })}
                    </p>
                    <p>
                        {intl.formatMessage({
                            id: 'welcome_congrats_secondline',
                            defaultMessage:
                                'for the future and time with your friends and family'
                        })}
                    </p>
                </div>
            </div>
        </div>
    );
};
