import React from 'react';
import { useIntl } from 'react-intl';
// @ts-ignore
import { InfoPanel } from '@workhuman/react-aurora-infopanel';
import { ReactComponent as TickIcon } from '@aurora/icon/svg/correct.svg';
import { withLayout } from '../shared/withLayout/withLayout';
import { AccountMessageLink } from '../shared/AccountMessageLink/AccountMessageLink';
import { useCurrentUser } from '../../store';
import styles from './PasswordInstructionsSent.module.scss';

export const PasswordInstructionsSent = withLayout(() => {
    const intl = useIntl();
    const [{ email }] = useCurrentUser();

    const heading = intl.formatMessage({
        id: 'passwordInstructionsSent__title',
        defaultMessage: 'Update instructions sent'
    });
    const description = intl.formatMessage(
        {
            id: 'passwordInstructionsSent__description',
            defaultMessage:
                'If an account exists for {email}, an e-mail will be sent with instructions to update password.'
        },
        { email }
    );

    return (
        <div
            data-testid="passwordInstructionsSent"
            className={styles['instructionsSent']}
        >
            <TickIcon className="a-icon a-icon--xxl a-icon--iconColor" />
            <InfoPanel
                heading={heading}
                description={description}
                infoPanelFooter={
                    <AccountMessageLink
                        className={styles['instructionsSent--have-account']}
                    />
                }
            />
        </div>
    );
}, false);
