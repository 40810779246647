import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const useCurrentSession = () => {
    const [session, setSession] = useState<CognitoUserSession | null>(null);

    useEffect(() => {
        Auth.currentSession()
            .then(result => setSession(result))
            .catch(() => setSession(null));
    }, []);

    return session;
};
