import messages from './messages';
import { MessageDescriptor } from 'react-intl';

export interface Language {
    code: string;
    translation: MessageDescriptor;
}

export const LANGUAGE_STORAGE_KEY = 'WH_LOGIN_LANG';

export const DEFAULT_LANGUAGE =
    localStorage.getItem(LANGUAGE_STORAGE_KEY) || 'en-us';

export const AVAILABLE_LANGUAGES: Language[] = [
    {
        code: 'en-us',
        translation: messages['en-us']
    },
    {
        code: 'es-es',
        translation: messages['es-es']
    },
    {
        code: 'es-419',
        translation: messages['es-419']
    }
];
