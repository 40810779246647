import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
// @ts-ignore
import { addCustomProperties } from 'heap-analytics';
import {
    TextInput,
    TextInputLabel,
    TextInputGroup
    // @ts-ignore
} from '@workhuman/react-aurora-textinput';
// @ts-ignore
import { Alert } from '@workhuman/react-aurora-alert';
import { ReactComponent as DangerIcon } from '@aurora/icon/svg/danger-filled.svg';
import { Spinner } from '../shared/Spinner/Spinner';
import { withLayout } from '../shared/withLayout/withLayout';
import { UserAuthForm } from '../shared/UserAuthForm/UserAuthForm';
import { useAccountActivationRequest } from './useAccountActivationRequest';
import { useStoreRedirect } from '../../hooks/useStoreRedirect/useStoreRedirect';
import { useRefreshCognitoSession } from '../../hooks/useRefreshCognitoSession/useRefreshCognitoSession';
import { getRetireePersonProfile } from '../../utils/person-profile-parser';
import { EMPLOYEE_ID_LENGTH, ACCESS_CODE_LENGTH } from '../../utils/constants';
import {
    EMPLOYEE_ID_ALREADY_ACTIVATED,
    RETIREE_NOT_FOUND
} from '../../utils/activation-error-codes';
import styles from './ActivateAccount.module.scss';
import { useCurrentUser } from '../../store';

const getPlainAccessCode = (accessCode: string) => {
    return accessCode.replace(/[^A-Z]/g, '');
};

const isValidEmployeeId = (employeeId: string) => {
    return !/\W/.test(employeeId);
};

export const ActivateAccount = withLayout(() => {
    const intl = useIntl();
    const history = useHistory();
    const [{ heap_external_id: heapExternalId }] = useCurrentUser();
    const requestAccountActivation = useAccountActivationRequest();

    const { goToStore, storeRedirectForm } = useStoreRedirect();

    const refreshCognitoSession = useRefreshCognitoSession();

    const [employeeId, setEmployeeId] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [hasEmployeeIdError, setHasEmployeeIdError] = useState(false);
    const [hasAccessCodeError, setHasAccessCodeError] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showGeneralError, setShowGeneralError] = useState(false);
    const [generalErrorMsg, setGeneralErrorMsg] = useState('');

    const retireeNotFoundMsg = intl.formatMessage({
        id: 'activateAccount__retireeNotFound',
        defaultMessage:
            'Unable to activate. Check your employee id, access code and then try again.'
    });

    const employeeAlreadyActivatedMsg = intl.formatMessage({
        id: 'activateAccount__employeeAlreadyActivated',
        defaultMessage:
            'This employee id has been already activated, with a different email address.'
    });

    const isEmployeeIdValid = useMemo(() => {
        return !!employeeId && isValidEmployeeId(employeeId);
    }, [employeeId]);

    const isAccessCodeValid = useMemo(() => {
        const plainAccessCode = getPlainAccessCode(accessCode);
        return (
            !!plainAccessCode && plainAccessCode.length === ACCESS_CODE_LENGTH
        );
    }, [accessCode]);

    useEffect(() => {
        setIsFormValid(isEmployeeIdValid && isAccessCodeValid);
    }, [isEmployeeIdValid, isAccessCodeValid]);

    const handleAccountActivationSuccess = useCallback(() => {
        refreshCognitoSession()
            .then(newSession => {
                const updatedToken = newSession.getIdToken();

                const rawPersonProfiles = updatedToken.payload.person_profiles;
                const retireeProfile = getRetireePersonProfile(
                    rawPersonProfiles
                );

                if (retireeProfile) {
                    const retireeClient = retireeProfile.client;
                    const heapExternalGuid =
                        heapExternalId ||
                        updatedToken.payload['heap_external_id'];
                    const customDimensions = {
                        identityExternalId: heapExternalGuid
                    };

                    /*
                     * Set custom user props to Heap
                     * */
                    addCustomProperties(retireeClient, customDimensions);
                    goToStore(updatedToken.getJwtToken(), retireeProfile);
                } else {
                    console.error(
                        'Unable to find retiree profile on refreshed session'
                    );
                    history.push('/error');
                }
            })
            .catch(err => {
                console.error('could not refresh session to go to store', err);
                history.push('/error');
            });
    }, [refreshCognitoSession, goToStore, history, heapExternalId]);

    const handleAccountActivationFailure = useCallback(
        (err: { code: string }) => {
            setShowGeneralError(true);
            switch (err.code) {
                case EMPLOYEE_ID_ALREADY_ACTIVATED:
                    setGeneralErrorMsg(employeeAlreadyActivatedMsg);
                    break;
                case RETIREE_NOT_FOUND:
                    setGeneralErrorMsg(retireeNotFoundMsg);
                    break;
                default:
                    history.push('/error');
                    break;
            }
        },
        [employeeAlreadyActivatedMsg, retireeNotFoundMsg, history]
    );

    const doActivateAccount = useCallback(() => {
        setIsLoading(true);
        setShowGeneralError(false);
        const plainAccessCode = getPlainAccessCode(accessCode);
        requestAccountActivation(employeeId, plainAccessCode)
            .then(handleAccountActivationSuccess)
            .catch(err => handleAccountActivationFailure(err.response.data))
            .finally(() => setIsLoading(false));
    }, [
        employeeId,
        accessCode,
        requestAccountActivation,
        handleAccountActivationSuccess,
        handleAccountActivationFailure
    ]);

    const onEmployeeIdChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            if (isValidEmployeeId(newValue)) {
                setEmployeeId(newValue);
            }
        },
        []
    );

    const validateEmployeeId = useCallback(() => {
        setHasEmployeeIdError(!isEmployeeIdValid);
    }, [isEmployeeIdValid]);

    const onAccessCodeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            const transformed = newValue.toUpperCase();
            setAccessCode(transformed);
        },
        []
    );

    const validateAccessCode = useCallback(() => {
        setHasAccessCodeError(!isAccessCodeValid);
    }, [isAccessCodeValid]);

    const employeeIdLabel = intl.formatMessage({
        id: 'activateAccount__employeeId',
        defaultMessage: 'Employee ID'
    });
    const accessCodeLabel = intl.formatMessage({
        id: 'activateAccount__accessCode',
        defaultMessage: 'Access code'
    });

    const employeeIdError = intl.formatMessage({
        id: 'activateAccount__employeeIdError',
        defaultMessage: 'Please provide your Employee ID'
    });

    const accessCodeError = intl.formatMessage({
        id: 'activateAccount__accessCodeError',
        defaultMessage: 'Please provide your Access code'
    });

    const getFormFields = useCallback(() => {
        return (
            <div className={styles['input-margin--bottom']}>
                <TextInput
                    isVertical={true}
                    className={clsx(
                        styles['input-responsive'],
                        styles['input-margin--topReset'],
                        'a-typography--body1'
                    )}
                    hasError={hasEmployeeIdError}
                >
                    <TextInputLabel
                        htmlFor={'employeeId'}
                        label={employeeIdLabel}
                    />

                    <TextInputGroup
                        data-testid={`activateAccountForm__employeeIdInput`}
                        id="employeeId"
                        name="employeeId"
                        label={employeeIdLabel}
                        required={true}
                        type="text"
                        autoComplete="off"
                        value={employeeId}
                        onChange={onEmployeeIdChange}
                        onBlur={validateEmployeeId}
                        minLength="1"
                        maxLength={EMPLOYEE_ID_LENGTH}
                    />
                </TextInput>

                {hasEmployeeIdError && (
                    <span
                        data-testid={`activateAccountForm__employeeIdInputError`}
                        className={clsx(styles['a-input-error'])}
                    >
                        {employeeIdError}
                    </span>
                )}

                <TextInput
                    isVertical={true}
                    className={clsx(
                        styles['input-responsive'],
                        styles['input-margin--topReset'],
                        'a-typography--body1'
                    )}
                    hasError={hasAccessCodeError}
                >
                    <TextInputLabel
                        htmlFor={'accessCode'}
                        label={accessCodeLabel}
                    />

                    <InputMask
                        value={accessCode}
                        onChange={onAccessCodeChange}
                        mask="aaaa - aaaa"
                        maskChar={null}
                        onBlur={validateAccessCode}
                    >
                        {(inputProps: any) => (
                            <TextInputGroup
                                data-testid={`activateAccountForm__accessCodeInput`}
                                id="accessCode"
                                name="accessCode"
                                label={accessCodeLabel}
                                required={true}
                                type="text"
                                autoComplete="off"
                                {...inputProps}
                            />
                        )}
                    </InputMask>
                </TextInput>
                {hasAccessCodeError && (
                    <span
                        data-testid={`activateAccountForm__accessCodeError`}
                        className={clsx(styles['a-input-error'])}
                    >
                        {accessCodeError}
                    </span>
                )}
                {showGeneralError && (
                    <Alert
                        severity="danger"
                        className={styles['activate-account--alert']}
                        text={
                            <>
                                <DangerIcon
                                    className={clsx(
                                        styles['activate-account--alert-icon'],
                                        'a-icon a-icon--s'
                                    )}
                                />
                                {generalErrorMsg}
                            </>
                        }
                    />
                )}
            </div>
        );
    }, [
        employeeId,
        employeeIdLabel,
        hasEmployeeIdError,
        onEmployeeIdChange,
        validateEmployeeId,
        accessCode,
        accessCodeLabel,
        onAccessCodeChange,
        validateAccessCode,
        hasAccessCodeError,
        employeeIdError,
        accessCodeError,
        generalErrorMsg,
        showGeneralError
    ]);

    const formTitle = intl.formatMessage({
        id: 'activateAccountForm__title',
        defaultMessage: 'Activate account'
    });
    const formDescription = intl.formatMessage({
        id: 'activateAccountForm__description',
        defaultMessage: "You're almost done!"
    });
    const submitBtnLabel = intl.formatMessage({
        id: 'activateAccountForm__submit',
        defaultMessage: 'Activate account'
    });

    return (
        <>
            <UserAuthForm
                id="activateAccountForm"
                formTitle={formTitle}
                formDescription={formDescription}
                hideEmail
                hidePassword
                emailFieldMessages={{}}
                passwordFieldMessages={{}}
                renderExtraFormFields={getFormFields}
                submitBtnLabel={isLoading ? <Spinner /> : submitBtnLabel}
                submitBtnDisabled={!isFormValid || isLoading}
                onSubmit={doActivateAccount}
            />
            {storeRedirectForm}
        </>
    );
});
