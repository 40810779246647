import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// @ts-ignore
import { InfoPanel } from '@workhuman/react-aurora-infopanel';
// @ts-ignore
import { Button } from '@workhuman/react-aurora-button';
import { ReactComponent as Correct } from '@aurora/icon/svg/correct-filled.svg';
import { useCurrentUser } from '../../store';
import { AccountMessageLink } from '../shared/AccountMessageLink/AccountMessageLink';
import { Spinner } from '../shared/Spinner/Spinner';
import { withLayout } from '../shared/withLayout/withLayout';
import { ReactComponent as PaperPlaneIcon } from './PaperPlane.svg';
import styles from './EmailConfirmationSent.module.scss';
import clsx from 'clsx';

export const EmailConfirmationSent = withLayout(() => {
    const intl = useIntl();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailResent, setIsEmailResent] = useState(false);
    const [{ email }] = useCurrentUser();

    const heading = intl.formatMessage({
        id: 'emailConfirmationSent__heading',
        defaultMessage: 'Email successfully sent!'
    });
    const description = intl.formatMessage(
        {
            id: 'emailConfirmationSent__description',
            defaultMessage:
                'An email has been sent to {email}. Please complete setup within 24hrs. If you do not see email please check your spam/junk folders.'
        },
        { email }
    );
    const resendMessage = intl.formatMessage({
        id: 'resendConfirmation__resend',
        defaultMessage: 'Resend verification email'
    });

    const resendConfirmation = useCallback(() => {
        setIsLoading(true);
        Auth.resendSignUp(email)
            .then(() => {
                setIsLoading(false);
                setIsEmailResent(true);
            })
            .catch(() => {
                setIsLoading(false);
                history.push('/error');
            });
    }, [history, email]);

    return (
        <section className={styles['emailConfirmationSent']}>
            <PaperPlaneIcon className="a-icon a-icon--xxl" />
            <InfoPanel
                heading={heading}
                description={description}
                className={styles['emailConfirmationSent--info-panel']}
            />
            <>
                <Button
                    variant="primary"
                    onClick={resendConfirmation}
                    data-testid="sendConfirmationBtn"
                    className={styles['resendConfirmation--button']}
                    iconName={isEmailResent ? 'correct' : ''}
                    disabled={isEmailResent}
                >
                    {isEmailResent && (
                        <Correct
                            className={clsx(
                                styles['resendConfirmation--icon'],
                                'a-icon a-icon--s a-button-icon '
                            )}
                        />
                    )}

                    {isLoading ? <Spinner /> : resendMessage}
                </Button>

                <AccountMessageLink
                    className={styles['emailConfirmationSent--have-account']}
                />
            </>
        </section>
    );
}, false);
