import React, { useCallback, useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import { useIntl } from 'react-intl';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';

import { Spinner } from '../shared/Spinner/Spinner';
import { AccountMessageLink } from '../shared/AccountMessageLink/AccountMessageLink';
import { withLayout } from '../shared/withLayout/withLayout';
import {
    setEmail as setUserEmail,
    useCurrentUser,
    useLangLocale
} from '../../store';
import {
    isEmailValid,
    isPasswordValid,
    getClientMetadata
} from '../../utils/utils';
import { useForgotPassword } from '../../hooks/useForgotPassword/useForgotPassword';
import { USERNAME_EXISTS } from '../../utils/cognito-error-codes';
import { CLIENT_METADATA_ADDITIONAL_PARAMS } from '../../utils/constants';

import { ReactComponent as TickIcon } from './tick.svg';
import styles from './Signup.module.scss';
import { UserAuthForm } from '../shared/UserAuthForm/UserAuthForm';
import {
    FormFieldMessages,
    UserAuthFormProps
} from '../shared/UserAuthForm/types';

export const Signup = withLayout(
    () => {
        const history = useHistory();
        const intl = useIntl();
        const [, dispatch] = useCurrentUser();
        const [{ language }] = useLangLocale();

        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [isPolicyChecked, setPolicyChecked] = useState(false);
        const [isSignUpEnabled, setIsSignUpEnabled] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const repeatedSignupMetadata = {
            [CLIENT_METADATA_ADDITIONAL_PARAMS.key_repeated_signup]:
                CLIENT_METADATA_ADDITIONAL_PARAMS.value_repeated_signup,
            [CLIENT_METADATA_ADDITIONAL_PARAMS.key_lang]: language
        };

        const emailErrorMessages: FormFieldMessages = {
            error: {
                empty: intl.formatMessage({
                    id: 'signup_email_error_message_empty',
                    defaultMessage: 'Please provide email'
                }),
                invalid: intl.formatMessage({
                    id: 'signup_email_error_message_invalid',
                    defaultMessage: 'Please enter a valid email address'
                })
            }
        };

        const serviceAndPrivacyLabel = (
            <>
                {intl.formatMessage({
                    id: 'signup_terms_and_policy_label',
                    defaultMessage: 'By signing up, I agree to the'
                })}{' '}
                <a
                    className={styles['signup-link--color']}
                    href="https://res1.globoforce.com/corporate/eng/WHCT_MSA.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {intl.formatMessage({
                        id: 'signup_service_agreement_link',
                        defaultMessage: 'Service Agreement'
                    })}
                </a>{' '}
                {intl.formatMessage({
                    id: 'signup_service_agreement_and',
                    defaultMessage: 'and'
                })}{' '}
                <a
                    className={styles['signup-link--color']}
                    href="http://res1.globoforce.com/corporate/eng/WHCT_privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {intl.formatMessage({
                        id: 'signup_policy_link',
                        defaultMessage: 'Privacy Policy'
                    })}
                </a>
            </>
        );

        useEffect(() => {
            setIsSignUpEnabled(
                isEmailValid(email) &&
                    isPasswordValid(password) &&
                    isPolicyChecked
            );
        }, [email, password, isPolicyChecked]);

        const handleEmailChange = useCallback((newEmail: string): void => {
            setEmail(newEmail);
        }, []);

        const handlePasswordChange = useCallback(
            (newPassword: string): void => {
                setPassword(newPassword);
            },
            []
        );

        const handlePolicyChange = (): void => {
            setPolicyChecked(!isPolicyChecked);
        };

        const navigateToSentEmailSuccess = useCallback(() => {
            dispatch(setUserEmail(email.toLowerCase()));
            history.push('/confirmationsent');
        }, [dispatch, email, history]);

        /*
         * Calling forgot password flow with extra metadata "repeatedSignup"
         * which lambda will check and send existing verified user a custom email.
         * */
        const handleUserExists = useForgotPassword(
            '/confirmationsent',
            '/confirmationsent',
            navigateToSentEmailSuccess
        );

        const handleSignUp = useCallback(
            (event: FormEvent<HTMLFormElement>): void => {
                event.preventDefault();

                setIsLoading(true);
                setIsSignUpEnabled(false);
                const userEmail = email.toLowerCase();

                Auth.signUp({
                    username: userEmail,
                    password: password
                })
                    .then(() => {
                        setIsSignUpEnabled(true);
                        navigateToSentEmailSuccess();
                    })
                    .catch(error => {
                        setIsSignUpEnabled(true);

                        if (error.code === USERNAME_EXISTS) {
                            handleUserExists(
                                userEmail,
                                getClientMetadata(repeatedSignupMetadata)
                            );
                        } else {
                            history.push('/error');
                        }
                        console.error(
                            'Unable to signup using provided email and password'
                        );
                    })
                    .finally(() => setIsLoading(false));
            },
            [
                email,
                password,
                navigateToSentEmailSuccess,
                handleUserExists,
                repeatedSignupMetadata,
                history
            ]
        );

        const formFooter: JSX.Element = (
            <AccountMessageLink className={styles['signup-login--text']} />
        );

        const tickBox: JSX.Element = (
            <div
                className={clsx(
                    styles['a-checkbox'],
                    styles['a-typography--body1'],
                    styles['input-margin--bottom']
                )}
            >
                <input
                    data-testid="termsCheckbox"
                    id="termsCheckbox"
                    type="checkbox"
                    className={styles['a-checkbox-input']}
                    value={`${isPolicyChecked}`}
                    onChange={handlePolicyChange}
                />
                <label
                    htmlFor="termsCheckbox"
                    className={styles['a-checkbox-inputLabel']}
                >
                    <TickIcon
                        name={'tick'}
                        className={clsx(
                            styles['signup-checkbox-icon--align'],
                            styles['signup-checkbox-icon']
                        )}
                    />
                    <div
                        className={clsx(
                            styles['signup-checkbox-text--align'],
                            'a-typography--body2'
                        )}
                    >
                        {serviceAndPrivacyLabel}
                    </div>
                </label>
            </div>
        );

        const formProps: UserAuthFormProps = {
            formTitle: intl.formatMessage({
                id: 'signup_title',
                defaultMessage: 'Sign up'
            }),
            formDescription: intl.formatMessage({
                id: 'signup_headline',
                defaultMessage: 'Retirement Award'
            }),
            onSubmit: handleSignUp,
            email: email,
            hideEmail: false,
            onEmailChange: (newEmail: string) => handleEmailChange(newEmail),
            emailFieldMessages: emailErrorMessages,
            password: password,
            onPasswordChange: (newPassword: string) => {
                handlePasswordChange(newPassword);
            },
            hidePassword: false,
            passwordFieldMessages: {
                error: { empty: '', invalid: '' },
                hint: intl.formatMessage({
                    id: 'signup_password_validation_hint',
                    defaultMessage:
                        'Must be at least 8 characters, 1 uppercase, 1 lowercase and 1 number'
                })
            },
            submitBtnLabel: !isLoading ? (
                intl.formatMessage({
                    id: 'signup_button',
                    defaultMessage: 'Sign up'
                })
            ) : (
                <Spinner />
            ),
            submitBtnDisabled: !isSignUpEnabled,
            onValidationChange: () => {},
            renderFormFooter: () => formFooter,
            renderExtraFormFields: () => tickBox,
            id: 'signupForm'
        };

        return <UserAuthForm {...formProps} />;
    },
    true,
    true
);
